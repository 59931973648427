import { Link as RouterLink } from 'react-router-dom'
import { Icon, Text, Flex, Link, Tooltip } from "@chakra-ui/react";
import { useTenantInfo } from '../../../hooks/useTenantInfo';
import { SetColors } from '../../../../ui/colors';

export interface NavlinkProps {
  path: string;
  icon: any;
  label: string;
  active?: boolean;
  setState?: (state: boolean) => void;
}

export function Extralinks({
  path,
  icon,
  label,
  active,
  setState = () => { }
}: NavlinkProps) {
  const { handleGetInfo } = useTenantInfo();

  const coloresConfig = SetColors()
  const coloresStorage = handleGetInfo({ key: "colores" });

  const main = coloresStorage?.main || coloresConfig?.main || "main"
  const secondary = coloresStorage?.secondary || coloresConfig?.secondary || "secondary"
  const variant = coloresStorage?.variant || coloresConfig?.variant || "variant"

  return (
    <Link
      as={RouterLink}
      display="flex"
      rounded="4px"
      w="100%"
      bg={active ? variant : "transparent"}
      _hover={{ bg: variant }}
      to={path}
      onClick={() => {
        setState(false);
      }}
    >
      <Flex
        p="15px 12px"
        alignItems="center"
        gap="10px"
        className="icon-box"
        pos={"relative"}
      >
        <Icon
          as={icon}
          boxSize="22px"
          color={active ? secondary : main}
        />

        <Text
          mr="30px"
          color={active ? secondary : main}
          fontSize="16px"
          fontStyle="normal"
          fontWeight={active ? "600" : "400"}
          lineHeight="18px"
          letterSpacing="-0.078px"
          whiteSpace={"nowrap"}
        >
          {label}
        </Text>
      </Flex>
    </Link>
  );
}
