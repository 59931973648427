import { Flex, Box, Icon, Text } from "@chakra-ui/react"
import { IoMailUnreadOutline } from "react-icons/io5"
import { CustomAvatar } from "../../../../shared/components/Avatar/CustomAvatar"
import { formatTimezone } from "../../../../shared/utils/functions/parseDate"
import { useTimeZone } from "../../../../shared/hooks/useTimeZone"
import { EventsInt } from "../../views/Matriculas/Information/TabItems/TabEventos"
import { BiCalendar } from "react-icons/bi"

interface Props {
    step: EventsInt;
}

export const Pendientes = ({ step }: Props) => {
    const timeZone = useTimeZone();

    return (
        <Flex
            gap="20px"
            position="relative"
        >
            <Flex
                direction="column"
                alignItems="center"
                position="relative"
            >
                <Box
                    w={"1px"}
                    bg="#DADADA"
                    h="full"
                    position="absolute"
                    top="25px"
                />

                <Box
                    w="8px"
                    h="8px"
                    bg="main"
                    rounded="full"
                    position="absolute"
                    top="6px"
                    zIndex="1"
                />

                <Box
                    w={"1px"}
                    bg="#DADADA"
                    h="full"
                    position="absolute"
                    top="25px"
                />

                <Flex
                    boxSize="50px"
                    bg="main"
                    rounded="full"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    zIndex="1"
                    mt="55px"
                >
                    <Icon as={IoMailUnreadOutline} boxSize="28px" color="purewhite" />
                </Flex>
            </Flex>

            <Flex
                direction="column"
                flex="1"
            >
                <Text
                    textTransform={"uppercase"}
                    color="#545763"
                    fontWeight={"400"}
                    fontSize="14px"
                    mb="35px"
                >
                    {formatTimezone({ date: step?.fecha, timeZone, customFormat: "dd 'de' MMMM, yyyy" })}
                </Text>

                <Flex
                    alignItems="center"
                    gap="10px"
                    mb="16px"
                >
                    <CustomAvatar
                        size="50px"
                        name={step?.destinatario}
                        showLetter
                    />

                    <Text
                        fontSize="14px"
                    >
                        Email {" "}
                        <Text as="span" fontWeight="700" textDecoration={"underline"}>programado</Text>
                    </Text>
                </Flex>

                <Flex
                    alignItems="center"
                    gap="5px"
                    mb="10px"
                >
                    <Icon
                        as={BiCalendar}
                        boxSize="21px"
                        color="#959595"
                        mr="5px"
                    />

                    <Text
                        fontWeight="400"
                        fontSize="14px"
                        color="#959595"
                    >
                        Fecha programada:
                    </Text>

                    <Text
                        fontWeight="400"
                        fontSize="14px"
                        color="#959595"
                    >
                        {formatTimezone({ date: step?.fecha, timeZone, customFormat: "dd/MM/yyyy HH:mm" })}
                    </Text>
                </Flex>

                <Flex
                    bg="purewhite"
                    border="1px solid"
                    borderColor="#E2E8F0"
                    rounded="20px"
                    p="20px"
                >
                    <Text
                        borderLeft="1px solid"
                        borderColor="main"
                        pl="10px"
                        fontWeight="700"
                        fontSize="14px"
                        textTransform="capitalize"
                    >
                        {step?.asunto}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )
}
