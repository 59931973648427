import { Flex, Icon, Image, Text, useDisclosure } from "@chakra-ui/react"
import { CustomButton } from "../../../../../shared/components/CustomElements/CustomButton"
import { HiCheckCircle, HiOutlineDocumentText } from "react-icons/hi"
import singrupo from '../../../../../assets/img/singrupo.png'
import { LuLayoutTemplate } from "react-icons/lu"
import { RxCube } from "react-icons/rx"
import { OnboardingStep } from "../../../../../shared/components/Cards/OnboardingStep"
import { useState } from "react"
import { useAuthContex } from "../../../../../shared/context/auth.context"
import { useModuloFundae } from "../../../../../shared/hooks/ModuloFundae"
import { NewGrupoModal } from "../../../components/Modals/NewGrupoModal"
import { NewGrupoNoFundae } from "../../../components/Modals/NewGrupoNoFundae"
import { useUserPolicy } from "../../../../../shared/hooks/PermissionPolicy"

interface Props {
    Refresh: () => void;
}

export const SinGrupos = ({ Refresh }: Props) => {
    const moduloFUNDAE = useModuloFundae();
    const { user } = useAuthContex();
    const { total } = useUserPolicy();
    const [showStep, setShowStep] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Flex
            px="75px"
            py="75px"
            direction="column"
            gap="85px"
        >
            <Flex
                alignItems="center"
                gap="100px"
            >
                <Flex
                    direction="column"
                    gap="30px"
                >
                    <Text
                        fontSize="28px"
                        fontWeight="600"
                        color="pureblack"
                    >
                        Crea tu primer grupo
                    </Text>

                    <Text
                        fontSize="16px"
                        fontWeight="400"
                        color="pureblack"
                    >
                        Para matricular nuevos alumnos, necesitarás crear previamente un grupo para asociar toda la información relativa al curso y a los participantes. ¿Qué puedes hacer con los grupos?
                    </Text>

                    <Flex
                        direction="column"
                        my="15px"
                        gap="15px"
                        fontSize="18px"
                        fontWeight="400"
                        color="pureblack"
                    >
                        <Flex
                            gap="7px"
                            alignItems="center"
                        >
                            <Icon as={HiCheckCircle} color="secondary" boxSize="25px" />
                            <Text>
                                Puedes asociar múltiples matrículas a los grupos y ver el progreso común
                            </Text>
                        </Flex>

                        <Flex
                            gap="7px"
                            alignItems="center"
                        >
                            <Icon as={HiCheckCircle} color="secondary" boxSize="25px" />
                            <Text>
                                Puedes asociar varios tutores al grupo y luego elegir entre estos para cada matrícula
                            </Text>
                        </Flex>

                        <Flex
                            gap="7px"
                            alignItems="center"
                        >
                            <Icon as={HiCheckCircle} color="secondary" boxSize="25px" />
                            <Text>
                                Las matrículas heredan la fecha de incio y fecha fin del grupo
                            </Text>
                        </Flex>
                    </Flex>


                    <Flex
                        w="fit-content"
                        h="fit-content"
                        pos={"relative"}
                    >
                        <CustomButton
                            button={{
                                label: "Crea tu primer grupo",
                                onClick: () => {
                                    onOpen()
                                    showStep && setShowStep(false)
                                },
                                isDisabled: false,
                                isLoading: false,
                            }}
                        />

                        {(showStep && (!user?.config?.grupos && total)) &&
                            <OnboardingStep
                                position={{
                                    top: "15px",
                                    left: "150px"
                                }}
                                title="Crea tu primer grupo"
                                description="Crea y gestiona grupos de alumnos, definiendo criterios y asignando estudiantes a estos grupos para facilitar la organización y la comunicación."
                                onClick={() => {
                                    setShowStep(false)
                                    onOpen()
                                }}
                            />
                        }
                    </Flex>
                </Flex>

                <Image
                    src={singrupo}
                    maxW="552px"
                    maxH="290px"
                    objectFit="cover"
                    w="100%"
                    h="100%"
                />
            </Flex>


            <Flex
                pt="40px"
                gap="60px"
            >
                <Flex
                    border="1px solid #4E5FE4"
                    rounded="15px"
                    py="30px"
                    px="30px"
                    gap="20px"
                    direction="column"
                    fontSize="18px"
                    color="pureblack"
                    flex="1"
                >
                    <Flex
                        p="15px"
                        bg="#02B29F"
                        rounded="7px"
                        alignItems="center"
                        justifyContent="center"
                        w="fit-content"
                        h="fit-content"
                    >
                        <Icon as={LuLayoutTemplate} boxSize="27px" color="purewhite" />
                    </Flex>

                    <Text
                        fontWeight="500"
                    >
                        Organiza y crea tu grupo sin complicaciones
                    </Text>

                    <Text
                        fontWeight="400"
                    >
                        Iniciar un nuevo grupo puede parecer un desafío, pero nuestra plataforma facilita cada paso del proceso.
                    </Text>
                </Flex>

                <Flex
                    border="1px solid #4E5FE4"
                    rounded="15px"
                    py="30px"
                    px="30px"
                    gap="20px"
                    direction="column"
                    fontSize="18px"
                    color="pureblack"
                    flex="1"
                >
                    <Flex
                        p="15px"
                        bg="#4E5FE4"
                        rounded="7px"
                        alignItems="center"
                        justifyContent="center"
                        w="fit-content"
                        h="fit-content"
                    >
                        <Icon as={RxCube} boxSize="27px" color="purewhite" />
                    </Flex>

                    <Text
                        fontWeight="500"
                    >
                        Estructura a tu manera
                    </Text>

                    <Text
                        fontWeight="400"
                    >
                        Configura tu grupo con flexibilidad. Define el curso y añade alumnos.
                    </Text>
                </Flex>

                <Flex
                    border="1px solid #4E5FE4"
                    rounded="15px"
                    py="30px"
                    px="30px"
                    gap="20px"
                    direction="column"
                    fontSize="18px"
                    color="pureblack"
                    flex="1"
                >
                    <Flex
                        p="15px"
                        bg="#FF855D"
                        rounded="7px"
                        alignItems="center"
                        justifyContent="center"
                        w="fit-content"
                        h="fit-content"
                    >
                        <Icon as={HiOutlineDocumentText} boxSize="27px" color="purewhite" />
                    </Flex>

                    <Text
                        fontWeight="500"
                    >
                        Administra estudiantes y grupos
                    </Text>

                    <Text
                        fontWeight="400"
                    >
                        Matricula a los alumnos de forma sencilla y organiza grupos para una experiencia de aprendizaje más personalizada y efectiva.
                    </Text>
                </Flex>
            </Flex>

            {moduloFUNDAE ?
                <NewGrupoModal
                    isOpen={isOpen}
                    onClose={onClose}
                    setRefreshTable={Refresh}
                />
                :
                <NewGrupoNoFundae
                    isOpen={isOpen}
                    onClose={onClose}
                    setRefreshTable={Refresh}
                />
            }
        </Flex>
    )
}