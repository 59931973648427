import { Button, Flex, Icon, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { InformationLeccion } from "./TabsLeccion/Informacion"
import { PreviewLeccion } from "./TabsLeccion/Preview";
import { Form as FormikForm, Formik } from 'formik';
import { CursosInt, LeccionInt, ModulosInt } from "../../../../interfaces/CursosInt";
import { useClient } from "../../../../shared/context/client.context";
import { loadContenido, removeLeccion, updateLeccion } from "../../../../shared/middlewares/lecciones.middleware";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { FormInput } from "@imaginagroup/bit-components.ui.form-input";
import { FiEdit3 } from "react-icons/fi";
import { useTenantInfo } from "../../../../shared/hooks/useTenantInfo";
import { LeccionTipoEnum, SubtipoLeccion } from "../../../../shared/utils/Types/LeccionTipoEnum";
import { BiTrash } from "react-icons/bi";
import { ModalDelete } from "../../../../shared/components/Modals/ModalDelete";
import { TipoSelect } from "../../views/Information/TabItem/TabContenido";
import { formatTimezone } from "../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../shared/hooks/useTimeZone";
import { getKey } from "../../../../shared/middlewares/config.middleware";
import { loginViaId } from "../../../../shared/middlewares/token.middleware";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { HiOutlineEye } from "react-icons/hi";

interface Props {
    leccion: LeccionInt | undefined;
    curso: CursosInt | undefined;
    setRefreshLeccion: Dispatch<SetStateAction<boolean>>;
    setSelected: Dispatch<SetStateAction<{ type: TipoSelect; data: ModulosInt | LeccionInt | null; } | undefined>>;
}

export const EditLeccion = ({ leccion, curso, setRefreshLeccion, setSelected }: Props) => {
    const { user } = useAuthContex();
    const toast = useToast();
    const client = useClient();
    const [file, setFile] = useState<File | null>(null);
    const [editName, setEditName] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const { handleGetInfo } = useTenantInfo();
    const colores = handleGetInfo({ key: "colores" });
    const timeZone = useTimeZone();
    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

    useEffect(() => {
        setEditName(false)
        setLoading(false)
        setFile(null)
    }, [leccion?.id])

    useEffect(() => {
        if (editName) {
            const inputElement: HTMLInputElement | null = document.querySelector('input[name="nombre"]');

            if (inputElement) {
                inputElement.focus();
            }
        }
    }, [editName])

    const initialValues = {
        nombre: leccion?.nombre,
        descripcion: leccion?.descripcion,
        contenido: leccion?.contenido,
        publicado: leccion?.publicado,
        sesionVivo: {
            online: leccion?.sesionVivo?.online,
            fechaInicio: formatTimezone({ date: leccion?.sesionVivo?.fechaInicio, timeZone: timeZone, customFormat: "yyyy-MM-dd'T'HH:mm" }),
            fechaFin: formatTimezone({ date: leccion?.sesionVivo?.fechaFin, timeZone: timeZone, customFormat: "yyyy-MM-dd'T'HH:mm" })
        }
    }

    const handleSubmit = async (values: any) => {
        setLoading(true);
        let newValue: Record<string, string | boolean | null> = {}

        Object.entries(values).forEach(([key, value]: [string, any]) => {
            if (
                key === "sesionVivo" && leccion?.tipo !== LeccionTipoEnum.SESION_VIVO
            ) {
                return
            }

            newValue = {
                ...newValue,
                [key]: value
            }
        })

        if (values?.contenido?.includes("<iframe") ||
            values?.contenido?.includes("</iframe>")) {
            setLoading(false)
            return toastNotify(toast, StatusEnumTypes.ERROR, "Debe ingresar solo la URL")
        }

        if (values?.contenido === "" || values?.contenido === "<p><br></p>") {
            newValue = {
                ...newValue,
                contenido: null
            }
        } else if (values?.descripcion === "") {
            newValue = {
                ...newValue,
                descripcion: null
            }
        }

        await updateLeccion({
            id: leccion?.id as string,
            data: newValue,
            client: client
        })
            .then(() => setRefreshLeccion(true))
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(async () => {
                if (file) {
                    await loadContenido({
                        id: leccion?.id as string,
                        client: client,
                        file: file
                    })
                        .then(() => {
                            setRefreshLeccion(true)
                            setFile(null)
                        })
                        .catch((error: any) => {
                            const errors = handleErrors(
                                error?.response?.data?.errors,
                                error?.response?.status
                            )

                            errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                        })
                }

                setLoading(false)
            })
    }

    const isExistsChanges = (
        values: {
            nombre: string | undefined;
            descripcion: string | undefined | null;
            contenido: string | undefined | null;
            publicado: boolean | undefined | null;
            sesionVivo: {
                online: boolean | undefined | null;
                fechaInicio: string | undefined | null;
                fechaFin: string | undefined | null;
            }
        }
    ) => {
        let initContent = leccion?.contenido
        let newContent = values?.contenido

        if (leccion?.tipo === LeccionTipoEnum.TEXTO || (leccion?.tipo === LeccionTipoEnum.ENTREGABLE && leccion?.subtipo === SubtipoLeccion.TEXTO)) {
            initContent = initContent?.replaceAll("<p><br></p>", "")?.replaceAll("\n", "")
            newContent = newContent?.replaceAll("<p><br></p>", "")?.replaceAll("\n", "")
        }

        if (leccion?.tipo === LeccionTipoEnum.SESION_VIVO) {
            const fechaInicio = formatTimezone({ date: leccion?.sesionVivo?.fechaInicio, timeZone: timeZone, customFormat: "yyyy-MM-dd'T'HH:mm" })
            const fechaFin = formatTimezone({ date: leccion?.sesionVivo?.fechaFin, timeZone: timeZone, customFormat: "yyyy-MM-dd'T'HH:mm" })

            if (
                values?.nombre !== leccion?.nombre ||
                values?.descripcion !== leccion?.descripcion ||
                values?.publicado !== leccion?.publicado ||
                values?.sesionVivo?.online !== leccion?.sesionVivo?.online ||
                values?.sesionVivo?.fechaInicio !== fechaInicio ||
                values?.sesionVivo?.fechaFin !== fechaFin ||
                initContent !== newContent ||
                file
            ) return true
        } else {
            if (
                values?.nombre !== leccion?.nombre ||
                values?.descripcion !== leccion?.descripcion ||
                values?.publicado !== leccion?.publicado ||
                initContent !== newContent ||
                file
            ) return true
        }

        return false
    }

    const updateName = async (nombre: string) => {
        if (!leccion?.id) return;

        return updateLeccion({
            id: leccion?.id,
            data: {
                nombre: nombre.trim()
            },
            client: client
        })
            .then(() => setRefreshLeccion(true))
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    const onDelete = () => {
        if (!leccion?.id) return

        removeLeccion({ id: leccion?.id, client: client })
            .then(() => {
                setRefreshLeccion(true)
                setSelected(undefined)
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Lección eliminada correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    const handleLeccionPreview = async () => {
        const { data } = await getKey("userURL", client);
        setIsLoggingIn(true);

        loginViaId({
            userId: user?.id as string,
            client: client
        })
            .then((response: any) => {
                const token = response?.data?.token?.token;

                const newWindow = window.open(`${data.data}/login?loginById=true&curso=${curso?.slug}&leccion=${leccion?.slug}&token=${token}`, "_blank");
                if (newWindow) {
                    newWindow.opener = null;
                }
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                );

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message));
            })
            .finally(() => setIsLoggingIn(false));
    }

    return (
        <Flex
            w="100%"
            direction="column"
            id="edit"
        >
            <Formik
                onSubmit={handleSubmit}
                enableReinitialize
                initialValues={initialValues}
            >
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                        >
                            <Flex
                                justifyContent="space-between"
                                mb="20px"
                            >
                                <Flex
                                    h="40px"
                                    alignItems="center"
                                >
                                    {!editName ?
                                        <Flex
                                            alignItems="center"
                                            gap="10px"
                                            mt="2px"
                                            onClick={() => setEditName(true)}
                                        >
                                            <Text
                                                fontSize="24px"
                                                fontWeight="400"
                                                color="pureblack"
                                                pl="2px"
                                            >
                                                {values?.nombre}
                                            </Text>

                                            <Icon
                                                as={FiEdit3}
                                                boxSize="18px"
                                                color="pureblack"
                                                cursor="pointer"
                                            />
                                        </Flex>
                                        :
                                        <FormInput
                                            name="nombre"
                                            isBlockError
                                            onBlur={() => {
                                                setEditName(false)

                                                if (values?.nombre !== leccion?.nombre) {
                                                    updateName(values?.nombre?.trim() as string)
                                                }
                                            }}
                                            ui={{
                                                styleInput: {
                                                    border: "none",
                                                    bg: "transparent",
                                                    width: "auto",
                                                    fontSize: "24px",
                                                    fontWeight: "400",
                                                    color: "pureblack",
                                                    pl: "2px",
                                                },
                                                focusInput: {
                                                    border: "none",
                                                    boxShadow: `0 0 0 1px ${colores.variant}`,
                                                },
                                            }}
                                        />
                                    }
                                </Flex>

                                <Flex
                                    alignItems="center"
                                    gap="10px"
                                    mr="5px"
                                >
                                    <Button
                                        display={isExistsChanges(values) ? "none" : "flex"}
                                        h="fit-content"
                                        w="fit-content"
                                        py="7.4px"
                                        px="10px"
                                        rounded={"6px"}
                                        bg="secondary"
                                        fontSize="14px"
                                        color="purewhite"
                                        fontWeight="400"
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()

                                            if (isOpenDelete) onCloseDelete();
                                            onOpenDelete();
                                        }}
                                    >
                                        <Icon
                                            boxSize="22px"
                                            as={BiTrash}
                                        />
                                    </Button>

                                    <Button
                                        display={isExistsChanges(values) ? "none" : "flex"}
                                        h="fit-content"
                                        w="fit-content"
                                        py="10px"
                                        px="10px"
                                        rounded={"6px"}
                                        bg="secondary"
                                        fontSize="14px"
                                        color="purewhite"
                                        fontWeight="400"
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()

                                            handleLeccionPreview()
                                        }}
                                        isDisabled={!leccion?.contenido}
                                        rightIcon={
                                            <Icon
                                                boxSize="22px"
                                                as={HiOutlineEye}
                                            />
                                        }
                                    >
                                        Ver lección en el campus
                                    </Button>

                                    <Text
                                        display={!isExistsChanges(values) ? "none" : "block"}
                                        color="fail"
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        Borrador
                                    </Text>

                                    <Button
                                        display={!isExistsChanges(values) ? "none" : "flex"}
                                        h="fit-content"
                                        w="fit-content"
                                        py="10px"
                                        px="10px"
                                        rounded={"6px"}
                                        bg="secondary"
                                        fontSize="14px"
                                        color="purewhite"
                                        fontWeight="400"
                                        type="submit"
                                        isDisabled={!leccion?.id || !isExistsChanges(values)}
                                        isLoading={loading}
                                    >
                                        Guardar
                                    </Button>
                                </Flex>
                            </Flex>


                            <Flex
                                direction="column"
                                css={{
                                    '&::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                    'scrollbarWidth': 'none',
                                    '-ms-overflow-style': 'none',
                                    scrollBehavior: 'smooth',
                                }}
                                overflow="auto"
                                h="calc(100vh - 240px)"
                            >
                                <InformationLeccion
                                    leccion={leccion}
                                    formik={formik}
                                    file={file}
                                    setFile={setFile}
                                />

                                {
                                    (
                                        leccion?.contenido &&
                                        leccion?.contenido !== "" &&
                                        leccion?.contenido !== "<p><br></p>" &&
                                        leccion?.contenido !== " "
                                    ) &&
                                    <PreviewLeccion
                                        leccion={leccion}
                                    />
                                }
                            </Flex>
                        </FormikForm>
                    );
                }}
            </Formik>

            <ModalDelete
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                onDelete={onDelete}
                label={"lección"}
            />
        </Flex>
    );
}
