import { useParams } from "react-router-dom";
import { TabPerfil } from "../../Empresas/views/Information/TabItems/TabPerfil";
import { useDataId } from "../../../shared/hooks/useDataId";
import { useClient } from "../../../shared/context/client.context";
import { EndpointTypes } from "../../../shared/utils/Types/EndpointTypes";
import { Flex, Image, Skeleton, Text, useToast } from "@chakra-ui/react";
import { defaultIcon } from "../../../shared/components/Icons/IconBox";
import { IconBox } from "../../../shared/components/Icons/IconBox";
import { Topbar } from "../../../shared/components/Topbar/Topbar";
import { toastNotify } from "../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../shared/utils/functions/handleErrors";
import { updateEmpresa } from "../../../shared/middlewares/empresas.middleware";
import { useState } from "react";

export const PerfilEmpresaInterna = () => {
    const toast = useToast();
    const { id } = useParams();
    const client = useClient();
    const [extraInfoState, setExtraInfoState] = useState<any>(null);
    const [horarioState, setHorarioState] = useState<any>(null);

    const { data: empresa, Refresh, loading } = useDataId({
        client,
        endpoint: EndpointTypes.EMPRESA,
        id: id!,
        ignoreRequest: !id,
    })

    const updateValue = (value: any) => {
        if (!empresa?.id) return;

        let updatedValue = value;

        if (value.extraInfo) {
            updatedValue = {
                ...value,
                extraInfo: {
                    ...extraInfoState,
                    ...value.extraInfo,
                    horario: {
                        ...horarioState,
                        ...value.extraInfo.horario
                    }
                }
            };
            setExtraInfoState(updatedValue.extraInfo);
            setHorarioState(updatedValue.extraInfo.horario);
        }

        return updateEmpresa({
            id: empresa?.id,
            data: updatedValue,
            client: client
        })
            .then(() => {
                Refresh()
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    return (
        <Topbar
            buttonBack="/configuracion/mis-empresas"
            title={
                loading ?
                    <Flex gap="15px" w="100%" alignItems="center">
                        <Skeleton size="55px"></Skeleton>

                        <Flex direction="column" gap="5px">
                            <Skeleton height="22px" width="200px"></Skeleton>

                            <Skeleton height="18px" width="200px"></Skeleton>
                        </Flex>
                    </Flex>
                    :
                    <Flex gap="15px">
                        {empresa?.icono?.url ?
                            <Image
                                src={empresa?.icono?.url}
                                boxSize="45px"
                                border="none"
                                padding="0"
                                objectFit="cover"
                            />
                            :
                            <IconBox
                                icon={defaultIcon}
                                size="45px"
                                border="none"
                                padding="0"
                                bg="transparent"
                            />
                        }

                        <Flex direction="column" justifyContent="center">
                            <Flex
                                alignItems="center"
                            >
                                <Text
                                    color="font"
                                    fontSize="17px"
                                    fontWeight="600"
                                    lineHeight="128%"
                                >
                                    {empresa?.nombre}
                                </Text>
                            </Flex>

                            <Text
                                color="font"
                                fontSize="10px"
                                fontWeight="500"
                                textTransform="uppercase"
                            >
                                {empresa?.cif ? "Cif: " + empresa?.cif : ""}
                            </Text>
                        </Flex>
                    </Flex>
            }
        >
            <Flex
                direction="column"
                p="30px"
            >
                <TabPerfil
                    empresa={empresa}
                    updateValue={updateValue}
                    setRefresh={Refresh}
                    loading={loading}
                    misEmpresas={true}
                    extraInfoState={extraInfoState}
                    horarioState={horarioState}
                    setExtraInfoState={setExtraInfoState}
                    setHorarioState={setHorarioState}
                />
            </Flex>
        </Topbar>
    )
}