import { Flex, FormLabel, Icon, Text } from "@chakra-ui/react";
import { SetStateAction, useEffect, useState } from "react";
import { FiltrosLabel, FiltrosNames, FiltrosTypes } from "../../utils/Types/FiltrosEnum";
import { getFiltro, getFiltrosActivos, getInitialFilter } from "../../utils/functions/filterUtils";
import { BiChevronDown, BiChevronUp, BiTrashAlt } from "react-icons/bi";
import { FilterTypes } from "./FiltersTypes";
import { useClient } from "../../context/client.context";
import { FilterSection } from "../../utils/Types/FilterSectionEnum";

export interface FilterInt {
    name: FiltrosNames;
    type: FiltrosTypes;
    label?: string
    section?: FilterSection;
    multi?: boolean;
    extraData?: boolean;
}

interface Props {
    filterElements?: FilterInt[];
    filterHeaders?: FilterInt[],
    allowToggle?: boolean;
    button?: JSX.Element;
    allowCleanFilter?: boolean;
    bgSelects?: string;
    showBody?: boolean;
    query: Record<string, string | number | string[]>;
    setQuery: (value: any) => void;
    setHasFilters?: (value: boolean) => void;
}

export const Filters = ({
    filterElements = [],
    filterHeaders = [],
    allowToggle = false,
    allowCleanFilter = true,
    button,
    bgSelects,
    showBody = true,
    query,
    setHasFilters = () => { },
    setQuery
}: Props) => {
    const client = useClient();
    const [loading, setLoading] = useState<boolean>(true)
    const [isOpen, setIsOpen] = useState<boolean>(allowToggle ? false : true);
    const [filtrosActivos, setFiltrosActivos] = useState<{
        key: string;
        nombre: string;
        clear: () => void;
    }[]>([])
    const [filtros, setFiltros] = useState<{
        name: string;
        value: string;
        label: string;
    }[]>([])

    useEffect(() => {
        if (client)
            getInitialFilter(query, filtros, setFiltros, client, [...filterElements, ...filterHeaders])
    }, [query])

    const clearAndReset = (setValue?: (value: null) => void) => setValue && setValue(null)

    useEffect(() => {
        const filtro = getFiltro(filtros)
        let options = {};
        options = query?.sortBy ? { ...options, sortBy: query?.sortBy } : options;
        options = query?.order ? { ...options, order: query?.order } : options;
        options = query?.page ? { ...options, page: query?.page } : options;
        options = query?.limit ? { ...options, limit: query?.limit } : options;
        options = filtro ? { ...filtro, ...options } : { ...options };

        if (Object.keys(filtro).length > 0) {
            options = { ...options, page: 1 }
        }

        setQuery({
            ...options
        });
    }, [filtros]);

    useEffect(() => {
        setLoading(true)
        const filters = getFiltrosActivos(filtros, query, setFiltros, setQuery, clearAndReset)
        setFiltrosActivos(filters)

        const timeout = setTimeout(() => { setLoading(false) }, 50)

        return () => clearTimeout(timeout)
    }, [query])

    useEffect(() => {
        if (filtrosActivos && filtrosActivos.length > 0)
            setHasFilters(true)
        else
            setHasFilters(false)
    }, [filtrosActivos])

    useEffect(() => {
        if (query) {
            const cleanFilters: SetStateAction<{ name: string; value: string; label: string; }[]> = [];


            filtros?.forEach((filter) => {
                if (query.hasOwnProperty(filter.name)) {
                    cleanFilters.push(filter)
                }
            })

            const filters = getFiltrosActivos(cleanFilters, query, setFiltros, setQuery, clearAndReset)
            setFiltrosActivos(filters)
        }
    }, [query])

    return (
        <Flex
            direction="column"
            gap="20px"
        >
            {(button || filterHeaders?.length > 0) &&
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    gap="10px"
                >
                    {filterHeaders?.map((filter: FilterInt, index: number) => (
                        <Flex
                            w="full"
                            direction="column"
                            gap="10px"
                        >
                            {filter?.label &&
                                <Text
                                    fontSize="14px"
                                    fontWeight="500"
                                    color="font"
                                >
                                    {filter?.label}
                                </Text>
                            }

                            <FilterTypes
                                key={index}
                                filter={filter}
                                isSearch={true}
                                clearAndReset={clearAndReset}
                                filtros={filtros}
                                setFiltros={setFiltros}
                                bgSelects={bgSelects}
                            />
                        </Flex>
                    ))}

                    {button && button}
                </Flex>
            }

            {showBody &&
                <Flex
                    bg="#FFFFFF"
                    borderWidth="1px"
                    p="20px"
                    borderRadius="14px"
                    boxSize="100%"
                    direction="column"
                    gap="15px"
                    boxShadow="0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)"
                    borderColor="#DFE2E6"
                    onClick={() => !isOpen ? setIsOpen(true) : null}
                    cursor={!isOpen ? "pointer" : "default"}
                >
                    <Flex
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Flex
                            alignItems="end"
                            gap="10px">
                            <Text fontSize="16px" fontStyle="normal">
                                Filtros de búsqueda
                            </Text>

                            {!loading &&
                                filtrosActivos?.length > 0 &&
                                filtrosActivos?.map((filtro: any, index: number) => (
                                    <Flex
                                        key={index}
                                        p="2px 5px"
                                        rounded="5px"
                                        color="black"
                                        bg="light_grey"
                                        alignItems="center"
                                        justifyContent="center"
                                        gap="10px"
                                    >
                                        <Text
                                            fontSize="13px"
                                            fontWeight="500"

                                        >
                                            {filtro?.nombre}
                                        </Text>

                                        {allowCleanFilter &&
                                            <Icon
                                                as={BiTrashAlt}
                                                color="dark_grey"
                                                boxSize="15px"
                                                mb="2px"
                                                textTransform="uppercase"
                                                cursor="pointer"
                                                _hover={{ color: "main" }}
                                                onClick={filtro?.clear}
                                            />
                                        }
                                    </Flex>
                                ))
                            }
                        </Flex>

                        {allowToggle &&
                            <Icon
                                as={isOpen ? BiChevronUp : BiChevronDown}
                                transition="2s"
                                boxSize="25px"
                                cursor="pointer"
                                onClick={() => setIsOpen((prev: boolean) => !prev)}
                            />
                        }
                    </Flex>

                    <Flex
                        w="100%"
                        justifyContent="space-between"
                        gap="30px"
                        transition="2s"
                        display={isOpen ? "flex" : "none"}
                    >

                        {filterElements?.map((filter: any, index: number) => (
                            <Flex
                                direction="column"
                                w="100%"
                                key={index}
                            >
                                <FormLabel
                                    color="font"
                                    fontSize="14px"
                                    fontWeight="500"
                                >
                                    {FiltrosLabel[filter?.name as keyof typeof FiltrosLabel]}
                                </FormLabel>

                                <FilterTypes
                                    filter={filter}
                                    clearAndReset={clearAndReset}
                                    filtros={filtros}
                                    setFiltros={setFiltros}
                                    bgSelects={bgSelects}
                                />
                            </Flex>
                        ))}
                    </Flex>
                </Flex>
            }
        </Flex>
    )
}