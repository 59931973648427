import { Flex, Text } from "@chakra-ui/react"
import { PreguntaInt } from "../../../../interfaces/ExamenInt"
import { Dispatch, SetStateAction } from "react"

interface Props {
    pregunta: PreguntaInt;
    setSelected: Dispatch<SetStateAction<PreguntaInt | undefined>>;
    selected: PreguntaInt | undefined;
}

export const PreguntasModule = ({
    pregunta,
    setSelected,
    selected,
}: Props) => {

    return (
        <Flex
            position={"relative"}
            display="flex"
            flexDirection="column"
            bg="purewhite"
            p="25px"
            rounded="10px"
            shadow="0px 1px 4px 0px rgba(9, 51, 72, 0.15)"
            border={"1px solid"}
            borderColor={selected?.id === pregunta?.id ? "secondary" : "transparent"}
            onClick={(event: React.MouseEvent) => {
                event.stopPropagation()

                setSelected(pregunta)
            }}
        >
            <>
                <Flex
                    display="flex"
                    alignItems="center"
                    gap="8px"
                    justifyContent="space-between"
                    w="100%"
                    cursor="pointer"
                >
                    <Text
                        color="pureblack"
                        fontSize="15px"
                        fontWeight="400"
                        lineHeight="100%"
                        letterSpacing="-0.16px"
                    >
                        {pregunta?.contenido}
                    </Text>
                </Flex>
            </>
        </Flex>
    )
}