import { Badge, Box, Flex, Icon, Progress, Text, Link, Tooltip } from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { AlertsGrupo } from "../../../../../../shared/components/CustomElements/AlertsGrupo";
import { EmpresasInt } from "../../../../../../interfaces/EmpresasInt";
import { Actions } from "../../../../../../shared/components/TableElements/Actions";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { UserRolEnum } from "../../../../../../shared/utils/Types/UserRolEnum";
import { isRoleAllowed } from "../../../../../../shared/utils/functions/validateRol";
import { useAuthContex } from "../../../../../../shared/context/auth.context";
import { ColumnIcon } from "@imaginagroup/bit-components.ui.columns.column-icon";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";
import { formatTimezone } from "../../../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../../../shared/hooks/useTimeZone";
import { CustomColumnProps } from "../../../../../../shared/components/Table/CustomGroupTable";
import { truncate } from "lodash";

interface Props {
    moduloFUNDAE: boolean;
    contenido: boolean;
    onEventRemove: (event: any) => void;
    onOpen: () => void;
    grupoRef: any;
    actions: boolean;
}

export const GruposColumns = ({
    moduloFUNDAE,
    contenido,
    onEventRemove,
    onOpen,
    grupoRef,
    actions
}: Props): CustomColumnProps[] => {
    const timeZone = useTimeZone();
    const { user } = useAuthContex();
    const location = useLocation();

    const deleteAcctions = (columns: CustomColumnProps[]) => {
        columns?.pop()

        return columns;
    }

    const columns: CustomColumnProps[] = [
        {
            header: 'Duracion',
            field: 'fechaInicio',
            key: 'fechaInicio',
            sortable: true,
            redirect: (id: string) => location.pathname.startsWith("/grupos") ? `/grupos/${id}/configuracion` : `/informes/${id}`,
            style: { width: "250px" },
            body: (rowData: any) => (
                <Flex direction="column" alignItems="flex-start">
                    <Flex
                        alignItems="center"
                        gap="8px"
                    >
                        <Badge
                            color={
                                rowData?.estado == 2 ? "font" :
                                    rowData?.estado == 1 ? "secondary"
                                        : "success_main"
                            }
                            bg={
                                rowData?.estado == 2 ? "light_grey" :
                                    rowData?.estado == 1 ? "variant"
                                        : "success_bg"
                            }
                            borderColor={
                                rowData?.estado == 2 ? "light_grey" :
                                    rowData?.estado == 1 ? "variant"
                                        : "success_variant"
                            }
                            borderWidth="1px"
                            fontSize="10px"
                            fontWeight="500"
                            letterSpacing="0.2px"
                            textTransform="uppercase"
                            borderRadius="14px"
                            p="2px 5px"

                        >
                            {
                                rowData?.estado == 2 ? "Inactivo" :
                                    rowData?.estado == 1 ? "Próximo"
                                        : "Activo"
                            }
                        </Badge>

                        {rowData?.rutaId &&
                            <Badge
                                color={"warning"}
                                bg={"warning_bg"}
                                borderColor={"warning_variant"}
                                borderWidth="1px"
                                fontSize="10px"
                                fontWeight="500"
                                letterSpacing="0.2px"
                                textTransform="uppercase"
                                borderRadius="14px"
                                p="2px 5px"

                            >
                                Ruta
                            </Badge>
                        }
                    </Flex>

                    <Flex
                        alignItems="center"
                        gap="5px"
                        color="font"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        <Text>{formatTimezone({ date: rowData?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                        <Text mb="2px">-</Text>
                        <Text>{formatTimezone({ date: rowData?.fechaFin, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                    </Flex>
                </Flex>
            )
        },
        {
            header: 'Nombre',
            field: 'cursos.nombre',
            key: 'nombre',
            sortable: true,
            style: { width: "200px" },
            redirect: (id: string) => location.pathname.startsWith("/grupos") ? `/grupos/${id}/configuracion` : `/informes/${id}`,
            body: (rowData: any) => (
                <Flex
                    alignItems="center"
                >
                    <Flex direction="column">
                        <Text
                            color="font"
                            fontSize="14px"
                            fontWeight="500"
                        >
                            {rowData?.nombre}
                        </Text>
                        <Text
                            color="#7A7D81"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            {rowData?.curso?.nombre}
                        </Text>
                    </Flex>

                    {(rowData?.fundae === true && moduloFUNDAE) &&
                        <Flex
                            ml="auto"
                        >
                            <AlertsGrupo grupo={rowData} />
                        </Flex>
                    }
                </Flex>
            )
        },
        {
            header: 'Empresa',
            field: 'empresa',
            key: 'empresa',
            redirect: (id: string) => location.pathname.startsWith("/grupos") ? `/grupos/${id}/configuracion` : `/informes/${id}`,
            body: (rowData: any) => (
                <Flex direction="column">
                    {rowData?.empresas?.map((empresa: EmpresasInt, index: number) => (
                        <Tooltip
                            label={empresa?.nombre}
                            hasArrow={true}
                        >
                            <Text
                                key={index}
                                color="font"
                                fontSize="14px"
                                fontWeight="400"
                            >
                                {truncate(empresa?.nombre, { length: 20 })}

                            </Text>
                        </Tooltip>
                    ))}
                </Flex>
            )
        },
        {
            header: 'Matrículas',
            field: 'totalMatriculas',
            key: 'totalMatriculas',
            sortable: true,
            redirect: (id: string) => location.pathname.startsWith("/grupos") ? `/grupos/${id}/configuracion` : `/informes/${id}`,
            style: { width: "100px" },
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={(rowData?.meta?.totalMatriculas || 0)}
                />
            ),
        },
        {
            header: 'Emp. Organizadora',
            field: 'empresaOrganizadora.nombre',
            key: 'empresaOrganizadora.nombre',
            redirect: (id: string) => location.pathname.startsWith("/grupos") ? `/grupos/${id}/configuracion` : `/informes/${id}`,
            body: (rowData: any) => (
                <ColumnSimpleText
                    tooltip={{ label: rowData?.empresaOrganizadora?.nombre, hasArrow: true }}
                    text={truncate(rowData?.empresaOrganizadora?.nombre, { length: 20 }) || "---"}
                />
            ),
        },
        {
            header: 'Progreso Matrículas',
            field: 'progresoMatriculas',
            key: 'progresoMatriculas',
            style: { width: "200px" },
            redirect: (id: string) => location.pathname.startsWith("/grupos") ? `/grupos/${id}/configuracion` : `/informes/${id}`,
            body: (rowData: any) => (
                <Flex gap="5px" alignItems="center" pr="10%">
                    <Progress
                        value={parseInt(rowData?.meta?.progresoLeccionesMatriculas) || 0}
                        flex="1"
                        h="7px"
                        w="150px"
                        rounded="10px"
                        sx={{ '& > div': { background: "main" } }}
                    />

                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {parseInt(rowData?.meta?.progresoLeccionesMatriculas) || 0} %
                    </Text>
                </Flex>
            )
        },
        {
            header: "",
            field: "",
            key: "",
            style: { width: "30px" },
            body: (rowData: any) => (
                <Flex pos="relative">
                    <Actions
                        duplicate={{
                            title: "Copiar grupo",
                            isDisabled: contenido,
                            disableAlert: contenido ? "No tiene permisos para realizar la acción" : undefined,
                            onClick: (e?: any) => {
                                grupoRef.current = rowData;

                                onOpen()
                            }
                        }}
                        remove={{
                            title: "Eliminar grupo",
                            isDisabled: rowData?.meta?.totalMatriculas > 0 || contenido,
                            disableAlert: contenido ? "No tiene permisos para realizar la acción" : "No se puede eliminar un grupo con matrículas",
                            onClick: () => {
                                onEventRemove(rowData)
                            }
                        }}
                    />
                </Flex>
            )
        }
    ];

    const extraColumns: CustomColumnProps[] = [
        {
            header: 'FUNDAE',
            field: 'fundae',
            key: 'fundae',
            style: { width: "100px" },
            sortable: true,
            redirect: (id: string) => location.pathname.startsWith("/grupos") ? `/grupos/${id}/configuracion` : `/informes/${id}`,
            body: (rowData: any) => (
                rowData?.fundae
                    ?
                    <Flex
                        display="flex"
                        alignItems="center"
                        gap="5px"
                    >
                        <Icon
                            color="#E9B418"
                            as={BsCheckCircleFill}
                            boxSize="18px"
                        />

                        <Tooltip
                            label={rowData?.gestionaEmpresa ? "Gestion Externa" : "Gestion Interna"}
                            placement="bottom"
                        >
                            <Badge
                                color={rowData?.gestionaEmpresa ? "main" : "purewhite"}
                                bg={rowData?.gestionaEmpresa ? "purewhite" : "variant"}
                                borderColor={rowData?.gestionaEmpresa ? "main" : "variant"}
                                borderWidth="1px"
                                fontSize="10px"
                                fontWeight="500"
                                letterSpacing="0.2px"
                                textTransform="uppercase"
                                borderRadius="14px"
                                p="2px 5px"

                            >
                                {rowData?.gestionaEmpresa ? "Ext" : "Int"}
                            </Badge>
                        </Tooltip>
                    </Flex>
                    :
                    <ColumnIcon
                        icon={BsXCircleFill}
                        ui={{
                            color: "#616675",
                            boxSize: "18px"
                        }}
                    />
            )
        },
    ];

    return (actions && isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre))
        ? (moduloFUNDAE ? [...columns.slice(0, 1), ...extraColumns, ...columns?.slice(1)] : columns)
        : deleteAcctions(moduloFUNDAE ? [...columns.slice(0, 1), ...extraColumns, ...columns?.slice(1)] : columns)
}