import { Box, Flex, Image, Text, useToast } from "@chakra-ui/react"
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes"
import { useClient } from "../../../../shared/context/client.context"
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox"
import { ColorPickerRowProps, InformationRow, InformationRowItems, InformationRowTypes, InputRowProps, SwitchSelectRowProps } from "../../../../shared/components/CustomElements/InformationBox/InformationRow"
import { updateCampusConfig } from "../../../../shared/middlewares/config.middleware"
import { handleErrors } from "../../../../shared/utils/functions/handleErrors"
import { toastNotify } from "../../../../shared/utils/functions/toastNotify"
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes"
import { useData } from "../../../../shared/hooks/useData"
import { configLabels, configOrder } from "../../../../shared/utils/functions/configCampus"
import { ConfigEnum } from "../../../../shared/utils/Types/ConfigTypes"
import { useTenantInfo } from "../../../../shared/hooks/useTenantInfo"
import { defaultIcon } from "../../../Onboarding/Onboarding"
import { InformationFilepond } from "../../../../shared/components/Information/InformationFilepond"
import { useEffect } from "react"
import { SetColors } from "../../../../ui/colors"

export const ConfigInformation = () => {
    const { handleSetInfo } = useTenantInfo();
    const client = useClient()
    const toast = useToast()
    const { data: tenant, Refresh: refreshTenant } = useData({
        endpoint: EndpointTypes.INFO_TENANT,
        client: client,
    });

    const { data, loading, Refresh } = useData({
        client,
        endpoint: EndpointTypes.CAMPUS_CONFIG_READ
    })

    useEffect(() => {
        if (tenant) {
            tenant?.colores && handleSetInfo({ key: "colores", value: tenant?.colores })
            tenant?.icono && handleSetInfo({ key: "icono", value: tenant?.icono })
            tenant?.nombre && handleSetInfo({ key: "nombre", value: tenant?.nombre })
        }

    }, [tenant])

    const updateValue = async (values: Record<string, string | object | boolean | number>) => {
        let newValues: any = {}

        Object.entries(values).map(([key, value]) =>
            newValues = {
                [key]: value
            }
        )

        updateCampusConfig({
            data: newValues,
            client: client
        })
            .then(() => {

                if (newValues[ConfigEnum.COLORES]) {
                    handleSetInfo({ key: "colores", value: newValues[ConfigEnum.COLORES] })
                }

                Refresh()
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    const configInfo: InformationRowItems[] = loading ? [] : [
        ...Object.entries(configOrder(data)).map(([key, value], index) => (
            (typeof value === "boolean") ?
                {
                    label: configLabels(key),
                    optionLeft: "Si",
                    optionRight: "No",
                    defaultValue: value,
                    onClick: (event: any) => updateValue({ [key as any]: event }),
                    isDisabled: false,
                    hideBorder: {
                        top: 0 !== index,
                        bottom: false
                    },
                } as SwitchSelectRowProps
                : key === ConfigEnum.COLORES ?
                    {
                        label: configLabels(key),
                        defaultValue: value,
                        updateValue: async (event) => {
                            await handleSetInfo({ key: "colores", value: (event as any)?.colores })
                            await updateValue(event)
                            await refreshTenant()
                            await Refresh()

                            return window.location.reload()
                        },
                        hideBorder: {
                            top: 0 !== index,
                            bottom: false
                        },
                    } as ColorPickerRowProps
                    :
                    {
                        label: configLabels(key),
                        name: key,
                        defaultValue: value,
                        isRequired: false,
                        isDisabled: key === ConfigEnum.USER_URL || key === ConfigEnum.TIME_ZONE,
                        updateValue: updateValue,
                        hideBorder: {
                            top: 0 !== index,
                            bottom: false
                        },
                    } as InputRowProps
        )),
    ]

    return (
        <Flex
            w="100%"
        >
            <InformationBox
                title="Detalles de la configuración"
            >
                <Box>
                    {configInfo?.map((item: InformationRowItems, index: number) => (
                        <InformationRow
                            key={index}
                            type={
                                item?.label === "Colores" ?
                                    InformationRowTypes?.COLOR_PICKER
                                    : typeof item?.defaultValue === "boolean"
                                        ? InformationRowTypes?.SWITCH
                                        : InformationRowTypes.INPUT
                            }
                            item={item}
                        />
                    ))}

                    <Flex
                        w="100%"
                        gap="20px"
                        alignItems="flex-start"
                        p="20px"
                    >
                        <Flex
                            flex="1"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Text
                                color="font"
                                fontSize={"12px"}
                                textTransform="uppercase"
                                fontWeight="600"
                            >
                                Icono de la plataforma
                            </Text>

                            {tenant?.icono ? (
                                <Image
                                    m="auto"
                                    boxSize="80px"
                                    rounded="10px"
                                    src={tenant?.icono}
                                />
                            ) : (
                                <Image
                                    m="auto"
                                    boxSize="80px"
                                    rounded="10px"
                                    src={`data:image/svg+xml;utf8, ${defaultIcon}`}
                                />
                            )}
                        </Flex>

                        <Flex
                            mr="25px"
                            flex="1"
                        >
                            <InformationFilepond
                                method="POST"
                                noHead={true}
                                name="icono"
                                label="Icono"
                                putEP={`${client}/tenant/uploadIcono`}
                                setRefresh={async () => {
                                    await refreshTenant()
                                    await Refresh()

                                    return window.location.reload()
                                }}
                                labelIdle="Arrastra o selecciona el logotipo"
                            />
                        </Flex>
                    </Flex>
                </Box>
            </InformationBox>
        </Flex>
    )
}
