import { Flex, Box, Icon, Text } from "@chakra-ui/react"
import { CustomAvatar } from "../../../../shared/components/Avatar/CustomAvatar"
import { formatTimezone } from "../../../../shared/utils/functions/parseDate"
import { useTimeZone } from "../../../../shared/hooks/useTimeZone"
import { EventsInt } from "../../views/Matriculas/Information/TabItems/TabEventos"
import { BiCalendar } from "react-icons/bi"
import { CgTemplate } from "react-icons/cg"
import { TbSubtask } from "react-icons/tb"

interface Props {
    step: EventsInt;
}

export const Tareas = ({ step }: Props) => {
    const timeZone = useTimeZone();

    return (
        <Flex
            gap="20px"
            position="relative"
        >
            <Flex
                direction="column"
                alignItems="center"
                position="relative"
            >
                <Box
                    w={"1px"}
                    bg="#DADADA"
                    h="full"
                    position="absolute"
                    top="-5px"
                />

                <Flex
                    boxSize="50px"
                    bg="main"
                    rounded="full"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    zIndex="1"
                >
                    <Icon as={TbSubtask} boxSize="28px" color="purewhite" />
                </Flex>

                <Box
                    w={"1px"}
                    bg="#DADADA"
                    h="full"
                    position="absolute"
                    bottom="-25px"
                />
            </Flex>

            <Flex
                direction="column"
                flex="1"
            >
                <Flex
                    alignItems="center"
                    gap="10px"
                    mb="16px"
                >
                    <CustomAvatar
                        size="50px"
                        name={step?.destinatario}
                        showLetter
                    />

                    <Text
                        fontSize="14px"
                    >
                        Tarea {" "}
                        <Text as="span" fontWeight="700" textDecoration={"underline"}>programada</Text>
                        {/* {" "} para {" "}
                        <Text as="span" fontWeight="700">{step?.destinatario}</Text> */}
                    </Text>
                </Flex>

                <Flex
                    bg="purewhite"
                    border="1px solid"
                    borderColor="#E2E8F0"
                    rounded="20px"
                    p="20px"
                    direction="column"
                    gap="16px"
                >
                    <Flex
                        alignItems="center"
                        gap="5px"
                    >
                        <Icon
                            as={BiCalendar}
                            boxSize="21px"
                            color="#959595"
                            mr="5px"
                        />

                        <Text
                            fontWeight="400"
                            fontSize="14px"
                            color="#959595"
                        >
                            Fecha de ejecución:
                        </Text>

                        <Text
                            fontWeight="500"
                            fontSize="14px"
                        >
                            {formatTimezone({ date: step?.fecha, timeZone, customFormat: "dd/MM/yyyy HH:mm" })}
                        </Text>
                    </Flex>

                    <Box
                        bg="#D9D9D9"
                        w="100%"
                        h="1px"
                    />

                    <Flex
                        alignItems="center"
                        gap="5px"
                    >
                        <Icon
                            as={CgTemplate}
                            boxSize="21px"
                            color="#959595"
                            mr="5px"
                        />

                        <Text
                            fontWeight="500"
                            fontSize="14px"
                            color="#959595"
                        >
                            Plantilla:
                        </Text>

                        <Text
                            fontWeight="400"
                            fontSize="14px"
                            textTransform="capitalize"
                        >
                            {step?.asunto}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}
