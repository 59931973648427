import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, Text, useDisclosure, ModalCloseButton } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { FormInput } from '@imaginagroup/bit-components.ui.form-input';
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { addUsuario } from "../../../../shared/middlewares/users.middleware";
import { ModalCloseAlert } from "../../../../shared/components/Modals/ModalCloseAlert";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { useState } from "react";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../../shared/context/client.context";
import { formSelectStyles } from "../../../../ui/formSelectStyles";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    setRefreshTable: () => void;
}

export const NewAdminModal = ({
    isOpen,
    onClose,
    setRefreshTable,
}: Props) => {
    const client = useClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const toast = useToast();
    const { isOpen: isOpenAlert, onOpen: onOpenAlert, onClose: onCloseAlert } = useDisclosure();

    const initialValues = {
        nombre: null,
        apellidos: null,
        email: null,
        password: null,
        confirmarPassword: null,
        subtipo: null,
    };

    const validationSchema = Yup.object({
        nombre: Yup.string()
            .required('El nombre es obligatorio.')
            .typeError('El nombre es obligatorio.'),
        apellidos: Yup.string()
            .required('Este campo es obligatorio.')
            .typeError('Este campo es obligatorio.'),
        email: Yup.string()
            .email()
            .required('El email es obligatorio.')
            .typeError('El email es obligatorio.'),
        password: Yup.string().required('La contraseña es obligatoria.'),
        confirmarPassword: Yup.string().oneOf([Yup.ref('password')], 'Las contraseñas no coinciden.'),
        subtipo: Yup.string().required('El tipo de administrador es obligatorio.'),
    });

    const handleSubmit = (values: any) => {
        setIsLoading(true)


        const newUsuario: any = {
            nombre: values?.nombre.trim(),
            apellidos: values?.apellidos.trim(),
            email: values?.email.trim(),
            password: values?.password,
            subtipo: values?.subtipo,
            roleId: 4,
        }

        addUsuario({
            data: newUsuario,
            client: client
        })
            .then(async (response: any) => {
                setRefreshTable();
                onClose();

                toastNotify(toast, StatusEnumTypes.SUCCESS, `${newUsuario.nombre} se ha creado correctamente`)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={() => null}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                size="xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent
                    maxH="95vh"
                    rounded="20px"
                >
                    <Box
                        color="black"
                    >
                        <ModalHeader
                            textAlign="start"
                            fontSize="24px"
                            fontWeight="600"
                            px="30px"
                            pt="30px"
                            pb="0px"
                        >
                            Nuevo administrador
                        </ModalHeader>

                        <ModalCloseButton onClick={() => onOpenAlert()} />
                    </Box>

                    <Box
                        overflowY="auto"
                        __css={{
                            '&::-webkit-scrollbar': {
                                w: '7px',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '20px',
                                bg: `light_grey`,
                            },
                        }}
                    >
                        <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                            {(formik) => {
                                const { handleSubmit, values } = formik;

                                return (
                                    <FormikForm
                                        onSubmit={handleSubmit}
                                    >
                                        <ModalBody
                                            p="30px"
                                        >
                                            <Flex
                                                direction="column"
                                                gap="10px"
                                            >
                                                <Text
                                                    color="black"
                                                    fontSize="18px"
                                                    fontWeight="700"
                                                    mb="5px"
                                                >
                                                    Datos del administrador
                                                </Text>

                                                <Flex gap="15px">
                                                    <FormInput
                                                        isRequired={!values?.nombre ? true : false}
                                                        name="nombre"
                                                        label="Nombre"
                                                        placeholder="Nombre del administrador"
                                                    />

                                                    <FormInput
                                                        isRequired={!values?.apellidos ? true : false}
                                                        name="apellidos"
                                                        label="Apellidos"
                                                        placeholder="Apellidos del administrador"
                                                    />
                                                </Flex>

                                                <FormInput
                                                    isRequired={!values?.email ? true : false}
                                                    name="email"
                                                    label="Email"
                                                    type="email"
                                                    placeholder="Email del administrador"
                                                />

                                                <Flex gap="15px">
                                                    <FormInput
                                                        isRequired={!values?.password ? true : false}
                                                        name="password"
                                                        label="Contraseña"
                                                        type="password"
                                                        placeholder="Contraseña"
                                                    />

                                                    <FormInput
                                                        isRequired={!values?.confirmarPassword ? true : false}
                                                        name="confirmarPassword"
                                                        label="Confirmar contraseña"
                                                        type="password"
                                                        placeholder="Confirmar contraseña"
                                                    />
                                                </Flex>


                                                <FormSelect
                                                    label="Tipo de administrador"
                                                    name="subtipo"
                                                    isRequired={!values?.subtipo ? true : false}
                                                    options={[
                                                        { label: "Gestion", value: "gestion" },
                                                        { label: "Contenido", value: "contenido" },
                                                    ]}
                                                    ui={{
                                                        formSelectStyles: formSelectStyles,
                                                        styleLabel: {
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            color: "font",
                                                        }
                                                    }}
                                                />
                                            </Flex>
                                        </ModalBody>

                                        <ModalFooter
                                            justifyContent="center"
                                        >
                                            <Button
                                                isLoading={isLoading}
                                                type="submit"
                                                bg="secondary"
                                                p="10px 25px"
                                                h="fit-content"
                                                rounded="5"
                                                color="purewhite"
                                                fontSize="18px"
                                                fontWeight="500"
                                                _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                            >
                                                Crear administrador
                                            </Button>
                                        </ModalFooter>
                                    </FormikForm>
                                );
                            }}
                        </Formik>
                    </Box>
                </ModalContent>
            </Modal>

            <ModalCloseAlert
                isOpen={isOpenAlert}
                onClose={onCloseAlert}
                onCloseForm={onClose}
            />
        </>
    )
}