import { Box, Button, Checkbox, Flex, FormLabel, Icon, Image, Img, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { EmpresasInt } from "../../../../../interfaces/EmpresasInt";
import { InformationBox } from "../../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input"
import { BiPencil, BiPlusCircle } from "react-icons/bi";
import { useEffect, useState } from "react";
import { NewSupervisorModal } from "../../../../Usuarios/components/Modals/NewSupervisorModal";
import { CustomAvatar } from "../../../../../shared/components/Avatar/CustomAvatar";
import { Skeleton } from "primereact/skeleton";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import { ContactoEmpresaModal } from "../../../components/ContactoEmpresaModal";
import { useModuloFundae } from "../../../../../shared/hooks/ModuloFundae";
import { InformationFilepond } from "../../../../../shared/components/Information/InformationFilepond";
import { useUserPolicy } from "../../../../../shared/hooks/PermissionPolicy";
import { useClient } from "../../../../../shared/context/client.context";
import { defaultIcon } from "../../../../Onboarding/components/OnboardingForm";
import { InformationPhoneInput } from "../../../../../shared/components/Information/InformationPhoneInput";

interface Props {
    empresa: EmpresasInt | undefined;
    updateValue: (values: any) => void;
    setRefresh: () => void;
    loading: boolean;
    misEmpresas?: boolean;
    extraInfoState?: any;
    horarioState?: any;
    setExtraInfoState?: (value: any) => void;
    setHorarioState?: (value: any) => void;
}

export const TabPerfil = ({
    empresa,
    updateValue,
    setRefresh,
    loading,
    misEmpresas = false,
    extraInfoState,
    horarioState,
    setExtraInfoState = () => { },
    setHorarioState = () => { }

}: Props) => {
    const moduloFUNDAE = useModuloFundae();
    const { gestion } = useUserPolicy();
    const client = useClient();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenContacto, onOpen: onOpenContacto, onClose: onCloseContacto } = useDisclosure();
    const [editNombre, setEditNombre] = useState<boolean>(false)
    const [editCif, setEditCif] = useState<boolean>(false)
    const [editSecto, setEditSecto] = useState<boolean>(false)
    const [editWeb, setEditWeb] = useState<boolean>(false)
    const [editTelefono, setEditTelefono] = useState<boolean>(false)
    const [editEmail, setEditEmail] = useState<boolean>(false)
    const [diasSeleccionados, setDiasSeleccionados] = useState<string[]>([]);

    useEffect(() => {
        if (empresa) {
            if (empresa.extraInfo?.horario?.dias) {
                setDiasSeleccionados(empresa.extraInfo.horario.dias.split(' ').filter(Boolean));
            } else {
                setDiasSeleccionados([]);
            }
            setExtraInfoState(empresa.extraInfo || null);
            setHorarioState(empresa.extraInfo?.horario || null);
        } else {
            setDiasSeleccionados([]);
            setExtraInfoState(null);
            setHorarioState(null);
        }
    }, [empresa]);

    const handleDiaChange = (dia: string) => {
        const oldSelect = diasSeleccionados;

        let nuevosDias;
        if (diasSeleccionados.includes(dia)) {
            nuevosDias = diasSeleccionados.filter(d => d !== dia);
        } else {
            nuevosDias = [...diasSeleccionados, dia];
        }

        nuevosDias.sort((a, b) => ["L", "M", "X", "J", "V", "S", "D"].indexOf(a) - ["L", "M", "X", "J", "V", "S", "D"].indexOf(b));

        setDiasSeleccionados(nuevosDias);

        try {
            const newHorario = {
                ...horarioState,
                dias: nuevosDias.join(' ')
            };
            setHorarioState(newHorario);
            updateValue({
                extraInfo: {
                    ...extraInfoState,
                    horario: newHorario
                }
            });
        } catch (err) {
            setDiasSeleccionados(oldSelect);
        }
    };

    return (
        loading ?
            <Flex gap="20px">
                <Flex direction="column" flex="1" gap="20px">
                    <Skeleton height="556px" borderRadius="10px" />
                    {moduloFUNDAE &&
                        <Skeleton height="211px" borderRadius="10px" />
                    }
                </Flex>

                <Flex direction="column" flex="1" gap="20px">
                    <Skeleton height="149.5px" borderRadius="10px" />
                    <Skeleton height="180px" borderRadius="10px" />
                </Flex>
            </Flex>
            :

            <Flex gap="20px">
                <Flex direction="column" flex="1">
                    <InformationBox title="Datos de la empresa">
                        <Box>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditNombre(true)}
                                onMouseLeave={() => setEditNombre(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="nombre"
                                    label="Nombre"
                                    defaultValue={empresa?.nombre || ""}
                                    updateValue={updateValue}
                                    isRequired={true}
                                />

                                <Flex boxSize="20px">
                                    {editNombre && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditCif(true)}
                                onMouseLeave={() => setEditCif(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="cif"
                                    label="cif"
                                    defaultValue={empresa?.cif || ""}
                                    updateValue={updateValue}
                                    isRequired={true}
                                />

                                <Flex boxSize="20px">
                                    {editCif && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                onMouseEnter={() => setEditEmail(true)}
                                onMouseLeave={() => setEditEmail(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="email"
                                    label="Email"
                                    defaultValue={empresa?.email}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editEmail && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditNombre(true)}
                                onMouseLeave={() => setEditNombre(false)}
                                gap="10px"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="numeroExpediente"
                                    label="Nº de expediente"
                                    defaultValue={empresa?.numeroExpediente || ""}
                                    updateValue={updateValue}
                                    isRequired={true}
                                />

                                <Flex boxSize="20px">
                                    {editNombre && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditTelefono(true)}
                                onMouseLeave={() => setEditTelefono(false)}
                                gap="10px"
                            >
                                <InformationPhoneInput
                                    name="telefono"
                                    label="teléfono"
                                    defaultValue={empresa?.telefono || ""}
                                    updateValue={updateValue}
                                    notValidNumber={() => toastNotify(toast, StatusEnumTypes.ERROR, "El numero no tiene un formato valido.")}
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                />

                                <Flex boxSize="20px">
                                    {editTelefono && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditSecto(true)}
                                onMouseLeave={() => setEditSecto(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="sector"
                                    label="sector"
                                    defaultValue={empresa?.sector || ""}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editSecto && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                onMouseEnter={() => setEditWeb(true)}
                                onMouseLeave={() => setEditWeb(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="web"
                                    label="web"
                                    defaultValue={empresa?.web || ""}
                                    updateValue={updateValue}
                                />

                                <Flex boxSize="20px">
                                    {editWeb && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                direction="column"
                                w="100%"
                            >
                                <FormLabel
                                    fontSize="12px"
                                    fontWeight="600"
                                    color="font"
                                    textTransform="uppercase"
                                >
                                    Icono
                                </FormLabel>

                                <Flex
                                    alignItems="center"
                                >
                                    <Flex
                                        flex="1"
                                    >
                                        {empresa?.icono?.url ?
                                            <Img
                                                m="auto"
                                                boxSize="75px"
                                                rounded="10px"
                                                src={empresa?.icono?.url}
                                            />
                                            :
                                            <Image
                                                src={`data:image/svg+xml;utf8,${defaultIcon}`}
                                                m="auto"
                                                boxSize="75px"
                                                rounded="10px"
                                            />
                                        }
                                    </Flex>

                                    <Flex
                                        flex="1"
                                        mr="30px"
                                    >
                                        <InformationFilepond
                                            noHead={true}
                                            name="icono"
                                            label="Icono"
                                            putEP={`${client}/empresas/${empresa?.id}`}
                                            isDisabled={!empresa?.id || gestion}
                                            setRefresh={setRefresh}
                                            labelIdle="Arrastra o selecciona el logotipo"
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Box>
                    </InformationBox>
                </Flex>

                <Flex direction="column" flex="1" display={misEmpresas ? "flex" : "none"}>
                    <InformationBox title="Configuración de la empresa">
                        <Box>
                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                            >
                                <InformationInput
                                    name="direccion"
                                    label="Dirección"
                                    defaultValue={extraInfoState?.direccion || ""}
                                    updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, ...values } })}
                                />

                                <Flex boxSize="20px" />
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                            >
                                <InformationInput
                                    name="codigoPostal"
                                    label="Codigo Postal"
                                    defaultValue={extraInfoState?.codigoPostal || ""}
                                    updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, ...values } })}
                                />

                                <Flex boxSize="20px" />
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                            >
                                <InformationInput
                                    name="localidad"
                                    label="Localidad"
                                    defaultValue={extraInfoState?.localidad || ""}
                                    updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, ...values } })}
                                />

                                <Flex boxSize="20px" />
                            </Flex>

                            <Text
                                pt="10px"
                                pl="10px"
                                fontSize="12px"
                                fontWeight="600"
                                color="font"
                                textTransform="uppercase"
                            >
                                Responsable
                            </Text>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <InformationInput
                                    name="responsable"
                                    label="Nombre"
                                    defaultValue={extraInfoState?.responsable || ""}
                                    updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, ...values } })}
                                />

                                <Flex boxSize="20px" />
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                            >
                                <InformationInput
                                    name="telefonoContacto"
                                    label="Teléfono"
                                    defaultValue={extraInfoState?.telefonoContacto || ""}
                                    updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, ...values } })}
                                />

                                <Flex boxSize="20px" />
                            </Flex>

                            <Text
                                pt="10px"
                                pl="10px"
                                fontSize="12px"
                                fontWeight="600"
                                color="font"
                                textTransform="uppercase"
                                mb="10px"
                            >
                                Horario
                            </Text>

                            <Flex
                                px="20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                                mb="10px"
                            >
                                <FormLabel
                                    flex="1"
                                    fontSize="12px"
                                    fontWeight="600"
                                    letterSpacing="0.24px"
                                    textTransform="uppercase"
                                >
                                    Primer Tramo
                                </FormLabel>

                                <Flex
                                    gap="10px"
                                    alignItems="center"
                                    flex="1"
                                >
                                    <InformationInput
                                        name="inicioTramo1"
                                        defaultValue={horarioState?.inicioTramo1 || ""}
                                        updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, horario: { ...horarioState, ...values } } })}
                                        ui={{
                                            colorIconCopy: "font",
                                            styleForm: {
                                                minW: "0"
                                            },
                                            styleInput: {},
                                            styleLabelContainer: {
                                                display: "none"
                                            }
                                        }}
                                    />

                                    <Text>
                                        -
                                    </Text>

                                    <InformationInput
                                        name="finTramo1"
                                        defaultValue={horarioState?.finTramo1 || ""}
                                        updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, horario: { ...horarioState, ...values } } })}
                                        ui={{
                                            colorIconCopy: "font",
                                            styleForm: {
                                                minW: "0"
                                            },
                                            styleInput: {},
                                            styleLabelContainer: {
                                                display: "none"
                                            }
                                        }}
                                    />
                                </Flex>
                            </Flex>

                            <Flex
                                px="20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                <FormLabel
                                    flex="1"
                                    fontSize="12px"
                                    fontWeight="600"
                                    letterSpacing="0.24px"
                                    textTransform="uppercase"
                                >
                                    Segundo Tramo
                                </FormLabel>

                                <Flex
                                    gap="10px"
                                    alignItems="center"
                                    flex="1"
                                >
                                    <InformationInput
                                        name="inicioTramo2"
                                        defaultValue={horarioState?.inicioTramo2 || ""}
                                        updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, horario: { ...horarioState, ...values } } })}
                                        ui={{
                                            colorIconCopy: "font",
                                            styleForm: {
                                                minW: "0"
                                            },
                                            styleInput: {},
                                            styleLabelContainer: {
                                                display: "none"
                                            }
                                        }}
                                    />

                                    <Text>
                                        -
                                    </Text>

                                    <InformationInput
                                        name="finTramo2" defaultValue={horarioState?.finTramo2 || ""}
                                        updateValue={async (values: any) => updateValue({ extraInfo: { ...extraInfoState, horario: { ...horarioState, ...values } } })}
                                        ui={{
                                            colorIconCopy: "font",
                                            styleForm: {
                                                minW: "0"
                                            },
                                            styleInput: {},
                                            styleLabelContainer: {
                                                display: "none"
                                            }
                                        }}
                                    />
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                            >
                                {["L", "M", "X", "J", "V", "S", "D"]?.map((dia: string) => (
                                    <Checkbox
                                        key={dia}
                                        gap="10px"
                                        flexDirection="row-reverse"
                                        fontSize="12px"
                                        fontWeight="600"
                                        color="font"
                                        value={dia}
                                        isChecked={diasSeleccionados.includes(dia)}
                                        onChange={() => handleDiaChange(dia)}
                                    >
                                        {dia}
                                    </Checkbox>
                                ))}

                                <Flex boxSize="20px" />
                            </Flex>


                            <Flex
                                p="10px 20px"
                                direction="column"
                                w="100%"
                            >
                                <FormLabel
                                    fontSize="12px"
                                    fontWeight="600"
                                    color="font"
                                    textTransform="uppercase"
                                >
                                    Cuño / Firma
                                </FormLabel>

                                <Flex
                                    alignItems="center"
                                >
                                    <Flex
                                        flex="1"
                                    >
                                        {empresa?.imagen?.url ?
                                            <Image
                                                m="auto"
                                                h="75px"
                                                rounded="10px"
                                                src={empresa?.imagen?.url}
                                            />
                                            :
                                            <Image
                                                src={`data:image/svg+xml;utf8,${defaultIcon}`}
                                                m="auto"
                                                h="75px"
                                                rounded="10px"
                                            />
                                        }
                                    </Flex>


                                    <Flex
                                        flex="1"
                                        mr="30px"
                                    >
                                        <InformationFilepond
                                            noHead={true}
                                            name="imagen"
                                            label="Firma"
                                            putEP={`${client}/empresas/${empresa?.id}`}
                                            isDisabled={!empresa?.id || gestion}
                                            setRefresh={setRefresh}
                                            labelIdle="Arrastra o selecciona la firma"
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Box>
                    </InformationBox>
                </Flex>

                <Flex direction="column" flex="1" display={misEmpresas ? "none" : "flex"}>
                    <InformationBox
                        title="Supervisores de la empresa"
                        component={
                            <Button
                                onClick={onOpen}
                                bg="secondary"
                                color="#FFF"
                                p="8px 15px"
                                borderRadius="5px"
                                fontSize="14px"
                                fontWeight="500"
                                leftIcon={<Icon as={BiPlusCircle} boxSize="20px" />}
                                _hover={{ bg: "variant" }}
                            >
                                Nuevo supervisor
                            </Button>
                        }
                    >
                        <Flex gap="20px" p="20px">
                            <Text
                                flex="1"
                                display="flex"
                                gap="3px"
                                color="font"
                                fontSize="12px"
                                fontWeight="600"
                                letterSpacing="0.24px"
                                textTransform="uppercase"
                            >
                                Supervisores
                            </Text>

                            <Flex
                                flex="1"
                                direction="column"
                                gap="10px"
                                pr="20px"
                            >
                                {(empresa?.users && empresa?.users?.length > 0) ?
                                    empresa?.users?.map((item: any, index: number) => (
                                        <Flex
                                            boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
                                            key={index}
                                            borderRadius="9px"
                                            p="10px 15px"
                                            bg="purewhite"
                                            gap="10px"
                                            alignItems="center"
                                        >
                                            <CustomAvatar
                                                name={item?.nombre + item?.apellidos}
                                                size="30px"
                                                src={item?.avatar?.url}
                                            />

                                            <Box>
                                                <Text
                                                    color="font"
                                                    fontSize="14px"
                                                    fontWeight="500"
                                                >
                                                    {item?.nombre} {item?.apellidos}
                                                </Text>

                                            </Box>
                                        </Flex>
                                    ))
                                    :
                                    <Text
                                        flex="1"
                                        display="flex"
                                        gap="3px"
                                        color="font"
                                        fontSize="13px"
                                        fontWeight="500"
                                        letterSpacing="0.24px"
                                    >
                                        No hay supervisores asignados
                                    </Text>
                                }
                            </Flex>
                        </Flex>
                    </InformationBox>

                    <InformationBox
                        title="Contactos de la empresa"
                        component={
                            <Button
                                onClick={onOpenContacto}
                                bg="secondary"
                                color="#FFF"
                                p="8px 15px"
                                borderRadius="5px"
                                fontSize="14px"
                                fontWeight="500"
                                leftIcon={<Icon as={BiPlusCircle} boxSize="20px" />}
                                _hover={{ bg: "variant" }}
                            >
                                Nuevo contacto
                            </Button>
                        }
                    >
                        <Flex gap="20px" p="20px">
                            <Text
                                flex="1"
                                display="flex"
                                gap="3px"
                                color="font"
                                fontSize="12px"
                                fontWeight="600"
                                letterSpacing="0.24px"
                                textTransform="uppercase"
                            >
                                Contactos
                            </Text>

                            <Flex
                                flex="1"
                                direction="column"
                                gap="10px"
                                pr="20px"
                            >
                                {(empresa?.personasContacto && empresa?.personasContacto?.length > 0) ?
                                    empresa?.personasContacto?.map((item: any, index: number) => (
                                        <Flex
                                            boxShadow="0px 4px 15px 0px rgba(199, 205, 255, 0.60)"
                                            key={index}
                                            borderRadius="9px"
                                            p="10px 15px"
                                            bg="purewhite"
                                            gap="20px"
                                        >
                                            <CustomAvatar
                                                name={item?.user?.nombre + item?.user?.apellidos}
                                                size="30px"
                                                src={item?.user?.avatar?.url}
                                            />

                                            <Flex
                                                direction="column"
                                                gap="3px"
                                            >
                                                <Text
                                                    color="font"
                                                    fontSize="15px"
                                                    fontWeight="600"
                                                    mb="7px"
                                                >
                                                    {item?.user?.nombre} {item?.user?.apellidos}
                                                </Text>

                                                <Text
                                                    color="font"
                                                    fontSize="13px"
                                                    fontWeight="500"
                                                >
                                                    <Text as="strong">Departamento: </Text>

                                                    {item?.departamento}
                                                </Text>

                                                <Text
                                                    color="font"
                                                    fontSize="13px"
                                                    fontWeight="500"
                                                >
                                                    <Text as="strong">Email: </Text>

                                                    {item?.user?.email}
                                                </Text>

                                                <Text
                                                    color="font"
                                                    fontSize="13px"
                                                    fontWeight="500"
                                                >
                                                    <Text as="strong">Telefono: </Text>

                                                    {item?.user?.telefono || "--"}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    ))
                                    :
                                    <Text
                                        flex="1"
                                        display="flex"
                                        gap="3px"
                                        color="font"
                                        fontSize="13px"
                                        fontWeight="500"
                                        letterSpacing="0.24px"
                                    >
                                        No hay contactos asignados
                                    </Text>
                                }
                            </Flex>
                        </Flex>
                    </InformationBox>
                </Flex>

                <NewSupervisorModal
                    isOpen={isOpen}
                    onClose={onClose}
                    setRefreshTable={setRefresh}
                    idEmpresa={empresa?.id}
                />

                <ContactoEmpresaModal
                    isOpen={isOpenContacto}
                    onClose={onCloseContacto}
                    setRefresh={setRefresh}
                    idEmpresa={empresa?.id}
                />
            </Flex>
    );
}