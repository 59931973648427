import { Flex, Text } from "@chakra-ui/react"
import { Route, Routes } from "react-router-dom"
import { InformationInformes } from "./views/Information";
import { Helmet } from "react-helmet";
import { GruposTable } from "../Grupos/views/Grupos/Table/GruposTable";
import { Topbar } from "../../shared/components/Topbar/Topbar";

export const Informes = () => {
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Helmet>
                <title>Informes </title>
            </Helmet>
            <Flex direction="column">
                <Routes>
                    <Route
                        index
                        element={
                            <>
                                <Helmet>
                                    <title>Informes</title>
                                </Helmet>

                                <Topbar
                                    title={
                                        <Text
                                            color="font"
                                            fontSize="20px"
                                            fontWeight="400"
                                        >
                                            Informes
                                        </Text>
                                    }
                                >
                                    <Flex
                                        direction="column"
                                        p="30px"
                                    >
                                        <GruposTable />
                                    </Flex>
                                </Topbar>
                            </>
                        }
                    />

                    <Route
                        path='/:id'
                        element={
                            <InformationInformes />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
}