import { Box, Button, FormLabel, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Text, useToast } from '@chakra-ui/react'
import * as Yup from "yup"
import { recoveryPassword } from '../../../shared/middlewares/token.middleware';
import { StatusEnum, notify } from '../../../shared/utils/functions/notify';
import { Formik, Form as FormikForm } from 'formik';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export const RecoveryModal = ({ onClose, isOpen }: Props) => {
    const toast = useToast();

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("El email debe ser valido")
            .required("El campo email debe estar completo"),
    });

    const initialValues = {
        email: null,
    };

    const submitForm = async (values: any) => {
        recoveryPassword(values.email)
            .then(() => {
                notify(toast, StatusEnum.success, "Se le ha enviado un email para recuperar la contraseña")

            })

        onClose();
    };

    return (
        <Modal
            size="xl"
            onClose={onClose}
            isOpen={isOpen}
            isCentered
        >
            <ModalContent
                bgColor="purewhite"
                borderWidth="1px"
                borderColor="light_grey"
            >

                <ModalHeader>
                    <Text color="font" fontSize="16px" fontWeight="500px" lineHeight="normal" mb="-10px" ml="5px">
                        Recuperar contraseña
                    </Text>
                </ModalHeader>

                <Formik
                    onSubmit={(values, { resetForm }) => {
                        submitForm(values);
                        resetForm();
                    }}
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                >
                    {(formik) => {
                        const { handleSubmit, handleChange } = formik;

                        return (
                            <FormikForm
                                onSubmit={handleSubmit}
                            >
                                <ModalBody p="10px 30px">

                                    <Box>
                                        <FormLabel fontSize="12px" fontWeight="600" color="font">Email</FormLabel>

                                        <Input
                                            variant='outline'
                                            placeholder='Email'
                                            name='email'
                                            size='md'
                                            focusBorderColor='secondary'
                                            bgColor="purewhite"
                                            borderWidth="1px"
                                            borderColor="light_grey"
                                            onChange={handleChange}
                                            data-cy="recovery-email-input"
                                        />
                                    </Box>

                                    {
                                        formik.touched.email && formik.errors.email
                                            ? (<Box h="18px" w="100%" color='red' fontSize="12px" mt="10px" data-cy="recovery-error-message">{formik.errors.email}</Box>)
                                            : <Box h="18px" w="100%" mt="10px"></Box>
                                    }
                                </ModalBody>

                                <ModalFooter gap="20px">
                                    <Button
                                        color="font"
                                        bgColor="purewhite"
                                        rounded="10px"
                                        fontSize="15px"
                                        fontWeight="400"
                                        lineHeight="normal"
                                        onClick={onClose}
                                        data-cy="recovery-cancel-button"
                                    >
                                        Cancelar
                                    </Button>

                                    <Button
                                        type='submit'
                                        color="purewhite"
                                        bgColor="main"
                                        rounded="10px"
                                        fontSize="15px"
                                        fontWeight="400"
                                        lineHeight="normal"
                                        _hover={{ color: "black", bgColor: "light_grey" }}
                                        data-cy="recovery-submit-button"
                                    >
                                        Recuperar
                                    </Button>
                                </ModalFooter>

                            </FormikForm>
                        );
                    }}
                </Formik>
            </ModalContent>

        </Modal>
    )
}