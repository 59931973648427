import { CategoriaProfesionalOptions } from "../../../../shared/utils/Types/TipoCategoriaProfesional";
import { NivelEstudiosOptions } from "../../../../shared/utils/Types/TipoNivelEstudios";

export const getFields = ({
    isTutorUnico,
    isEmpresaUnica,
    tutores,
    empresas = [],
    moduloFUNDAE
}: {
    isTutorUnico: boolean | undefined | null;
    isEmpresaUnica: boolean | undefined;
    tutores: { value: string, label: string }[],
    empresas: { value: string, label: string }[],
    moduloFUNDAE: boolean | undefined;
}) => {
    const fields = [
        {
            label: "Nombre",
            key: "nombre",
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El nombre es obligatorio",
                    level: "error",
                },
            ],
        },
        {
            label: "Apellidos",
            key: "apellidos",
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El nombre es obligatorio",
                    level: "error",
                },
            ],
        },
        {
            label: "Email",
            key: "email",
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El nombre es obligatorio",
                    level: "error",
                },
            ],
        },
        {
            label: "Teléfono",
            key: "telefono",
            fieldType: {
                type: "input",
            },
            validations: [],
        },
        {
            label: "Nif o Nie",
            key: "dni",
            fieldType: {
                type: "input",
            },
            validations: [],
        },
    ]

    if (!isTutorUnico && isTutorUnico !== null) {
        fields.push({
            label: "Tutor",
            key: "tutorId",
            fieldType: {
                type: "select",
                options: tutores
            } as { type: "select"; options: any[] },
            validations: [
                {
                    rule: "required",
                    errorMessage: "El tutor es obligatorio",
                    level: "error",
                },
            ],
        })
    }

    if (!isEmpresaUnica) {
        fields.push(
            {
                label: "Empresa",
                key: "empresaId",
                fieldType: {
                    type: "select",
                    options: empresas
                } as { type: "select"; options: any[] },
                validations: [
                    {
                        rule: "required",
                        errorMessage: "La empresa es obligatoria",
                        level: "error",
                    },
                ],
            })
    }

    if (moduloFUNDAE) {
        fields.push(
            {
                label: "Nivel de estudios",
                key: "nivelEstudios",
                fieldType: {
                    type: "select",
                    options: NivelEstudiosOptions
                } as { type: "select"; options: any[] },
                validations: [
                    {
                        rule: "required",
                        errorMessage: "El nivel de estudios es obligatorio",
                        level: "error",
                    },
                ],
            },
            {
                label: "Cat. profesional",
                key: "categoriaProfesional",
                fieldType: {
                    type: "select",
                    options: CategoriaProfesionalOptions
                } as { type: "select"; options: any[] },
                validations: [
                    {
                        rule: "required",
                        errorMessage: "La categoría profesional es obligatoria",
                        level: "error",
                    },
                ],
            },
            {
                label: "Coste/hora",
                key: "costeHora",
                fieldType: {
                    type: "input",
                },
                validations: [
                    {
                        rule: "required",
                        errorMessage: "El coste por hora es obligatorio",
                        level: "error",
                    },
                ],
            },
            {
                label: "Fijo discontinuo",
                key: "fijoDiscontinuo",
                fieldType: {
                    type: "checkbox",
                },
                validations: [],
            }
        )
    }

    return fields;
}