import { Flex, Text, useToast } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateUsuario } from "../../../../../shared/middlewares/users.middleware";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import { CustomAvatar } from "../../../../../shared/components/Avatar/CustomAvatar";
import { loginViaId } from "../../../../../shared/middlewares/token.middleware";
import { UserTypesEnum } from "../../../../../shared/utils/Types/UserTypesEnum";
import { BiFolder, BiLogInCircle, BiUserCircle } from "react-icons/bi";
import { TabTable } from "../../TabItems/TabTable";
import { TabPerfil } from "../../TabItems/TabPerfil";
import { Skeleton } from "primereact/skeleton";
import { handleErrors } from "../../../../../shared/utils/functions/handleErrors";
import { useClient } from "../../../../../shared/context/client.context";
import { isRoleAllowed } from "../../../../../shared/utils/functions/validateRol";
import { UserRolEnum } from "../../../../../shared/utils/Types/UserRolEnum";
import { useAuthContex } from "../../../../../shared/context/auth.context";
import { EndpointTypes, RelationshipTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { Topbar } from "../../../../../shared/components/Topbar/Topbar";
import { TabLogs } from "../../../../Emails/views/TabItems/TabLogs";
import { TabPendientes } from "../../../../Emails/views/TabItems/TabPendientes";
import { TabTareas } from "../../../../Emails/views/TabItems/TabTareas";
import { MdOutlineMarkEmailRead, MdOutlineMarkEmailUnread } from "react-icons/md";
import { TbSubtask } from "react-icons/tb";
import { getKey } from "../../../../../shared/middlewares/config.middleware";
import { useModuloFundae } from "../../../../../shared/hooks/ModuloFundae";
import { EmailsSectionTypes } from "../../../../../shared/utils/Types/EmailsSectionTypes";
import { TabsRouterInt } from "../../../../../interfaces/ComponentsInt";
import { ShowMatriculasAlumnos } from "../../../components/TabsColumns/MatriculasAlumnos";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { useDataId } from "../../../../../shared/hooks/useDataId";
import { useDataRelationship } from "../../../../../shared/hooks/useDataRelationship";
import { TabsRouter } from "../../../../../shared/components/TabsRouter/TabsRouter";

export const InformationAlumno = () => {
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.MATRICULAS_ALUMNO });
    const moduloFUNDAE = useModuloFundae();
    const { id } = useParams();
    const client = useClient();
    const { user } = useAuthContex();
    const toast = useToast();
    const { data: usuario, loading, Refresh } = useDataId({
        id: id,
        client: client,
        endpoint: EndpointTypes.USUARIOS,
    });

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const subPaths = [
            `/usuarios/alumnos/${id}/perfil`,
            `/usuarios/alumnos/${id}/matriculas`,
            `/usuarios/alumnos/${id}/enviados`,
            `/usuarios/alumnos/${id}/pendientes`,
            `/usuarios/alumnos/${id}/tareas`,
        ];

        const isSubPath = subPaths.includes(location.pathname);

        if (!isSubPath) {
            navigate("perfil");
        }
    }, [location.pathname]);

    const { data: matriculas, loading: loadingMatriculas } = useDataRelationship({
        id: id,
        client: client,
        endpoint: EndpointTypes.USUARIOS,
        relationship: RelationshipTypes.MATRICULAS,
        ignoreRequest: !location.pathname.includes("matriculas"),
        query: query,
    });

    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

    const updateValue = (value: any) => {
        if (!id) return;

        if (value?.nombre) value.nombre = value.nombre.trim()
        if (value?.apellidos) value.apellidos = value.apellidos.trim()
        if (value?.email) value.email = value.email.trim()

        return updateUsuario({
            id: id,
            data: value,
            client: client
        })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Actualizacion correcta")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    const handleLoginViaId = async () => {
        if (!usuario?.id) return;

        const { data } = await getKey("userURL", client);
        setIsLoggingIn(true);

        loginViaId({
            userId: usuario?.id,
            client: client
        })
            .then((response) => {
                const token = response?.data?.token?.token;

                const newWindow = window.open(`${data.data}/login?loginById=true&token=${token}`, "_blank");
                if (newWindow) {
                    newWindow.opener = null;
                }
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                );

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message));
            })
            .finally(() => setIsLoggingIn(false));
    };

    const tabs: TabsRouterInt[] = [
        {
            isAllowed: true,
            label: "Perfil",
            path: "perfil",
            icon: BiUserCircle,
            content:
                <TabPerfil
                    usuario={usuario}
                    updateValue={updateValue}
                    userType={UserTypesEnum.ALUMNO}
                    setRefresh={Refresh}
                    loading={loading}
                />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Matrículas",
            path: "matriculas",
            icon: BiFolder,
            content:
                <TabTable
                    query={query}
                    setQuery={setQuery}
                    data={matriculas}
                    columns={ShowMatriculasAlumnos({ navigate, moduloFUNDAE })}
                    loading={loadingMatriculas}
                    userType={UserTypesEnum.ALUMNO}
                />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Emails",
            path: "enviados",
            icon: MdOutlineMarkEmailRead,
            content:
                <TabLogs
                    id={usuario?.id}
                    tipo={EmailsSectionTypes.USUARIOS}
                    hideFilters={true}
                />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Pendientes",
            path: "pendientes",
            icon: MdOutlineMarkEmailUnread,
            content:
                <TabPendientes
                    id={usuario?.id}
                    tipo={EmailsSectionTypes.USUARIOS}
                    hideFilters={true}
                />
        },
        {
            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
            label: "Tareas",
            path: "tareas",
            icon: TbSubtask,
            content:
                <TabTareas
                    id={usuario?.id}
                    tipo={EmailsSectionTypes.USUARIOS}
                    hideFilters={true}
                />
        },

        // TODO: Proxima implementación
        // {
        //     isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
        //     label: "Audits",
        //     icon: RiSwapBoxLine,
        //     content:
        //         <TabAudits
        //             tipo={AuditsTipoEnum.USER}
        //         />
        // },
    ]

    return (
        <Topbar
            title={
                loading ?
                    <Flex gap="15px" alignItems="center">
                        <Skeleton shape="circle" size="55px"></Skeleton>

                        <Flex direction="column" gap="8px">
                            <Skeleton height="22px" width="250px"></Skeleton>

                            <Skeleton height="18px" width="250px"></Skeleton>
                        </Flex>
                    </Flex>
                    :
                    <Flex gap="15px" alignItems="center">
                        <CustomAvatar
                            name={usuario?.nombre + usuario?.apellidos || ""}
                            size="35px"
                            src={usuario?.avatar?.url}
                        />

                        <Flex
                            direction="column"
                            justifyContent="space-between"
                            whiteSpace="nowrap"
                        >
                            <Text
                                color="font"
                                fontSize="17px"
                                fontWeight="600"
                                lineHeight="120%"
                            >
                                {usuario?.nombre} {usuario?.apellidos}
                            </Text>

                            <Text
                                color="font"
                                fontSize="12px"
                                fontWeight="500"
                            >
                                {usuario?.email}
                            </Text>
                        </Flex>
                    </Flex>
            }
            buttonBack="/usuarios/alumnos"
        >
            <Flex
                direction="column"
                px="30px"
                py="12px"
                borderTop="1px solid"
                borderColor="light_grey"
            >
                <TabsRouter
                    tabs={tabs}
                    buttons={[
                        {
                            isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
                            paths: ["perfil", "matriculas", "enviados", "pendientes", "tareas"],
                            button: {
                                isDisabled: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) ? false : true,
                                onClick: handleLoginViaId,
                                isLoading: isLoggingIn,
                                label: "Ingresar como alumno",
                                rightIcon: {
                                    as: BiLogInCircle,
                                    boxSize: "17px",
                                },
                            }
                        }
                    ]}
                />
            </Flex>
        </Topbar>

    );
}