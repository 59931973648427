import { Flex, Text } from "@chakra-ui/react";
import { Topbar } from "../../../shared/components/Topbar/Topbar";
import { TabsRouter } from "../../../shared/components/TabsRouter/TabsRouter";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { GruposTable } from "./Grupos/Table/GruposTable";

export const TabsGrupos = () => {
    const location = useLocation();

    // const tabs: TabsRouterInt[] = [
    //     {
    //         isAllowed: true,
    //         label: "Grupos",
    //         path: "listado",
    //         icon: BiBookReader,
    //         content:
    //             <GruposTable
    //                 actions={true}
    //             />
    //     },
    //     {
    //         isAllowed: isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre),
    //         label: "Timeline",
    //         path: "timeline",
    //         icon: MdOutlineAppRegistration,
    //         content: <Timeline />
    //     },
    // ]

    return (
        <>
            <Helmet>
                <title>Grupos </title>
            </Helmet>

            <Topbar
                title={
                    <Text
                        color="font"
                        fontSize="20px"
                        fontWeight="400"
                    >
                        {
                            location?.pathname?.includes("timeline") ? "Timeline"
                                : "Grupos"
                        }
                    </Text>
                }
            >
                <Flex
                    direction="column"
                    p="30px"
                >
                    <GruposTable
                        actions={true}
                    />
                </Flex>
            </Topbar>
        </>
    );
};
