import { useParams } from "react-router-dom";
import { useClient } from "../../../../../../shared/context/client.context";
import { Flex, Text } from "@chakra-ui/react";
import { useDataId } from "../../../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../../../shared/utils/Types/EndpointTypes";
import { Enviados } from "../../../../components/EventosItems/Enviados";
import { Pendientes } from "../../../../components/EventosItems/Pendientes";
import { Tareas } from "../../../../components/EventosItems/Tareas";

enum EventsTypes {
    ENVIADO = "email_enviado",
    PENDIENTE = "email_pendiente",
    TAREA = "tarea",
}

export interface EventsInt {
    tipo: EventsTypes;
    id: string;
    fecha: string;
    cantidad: number;
    usuarios: {
        usuario: string;
        eventos: {
            initial_open: string,
            open: string
        };
        asunto: string;
    }[]
}

export const TabEventos = () => {
    const { id } = useParams();
    const client = useClient();
    const { data: cronograma, loading } = useDataId({
        endpoint: EndpointTypes.GRUPOS,
        id: id + "/cronograma",
        client: client,
    })

    console.log(cronograma)

    return (
        loading
            ?
            <div />
            :
            <Flex
                direction="column"
                gap="20px"
                minH={"calc(100vh - 210px)"}
            >
                <Text
                    mt="20px"
                    ml="20px"
                    color="#545763"
                    fontSize="14px"
                    fontWeight="700"
                    borderBottom="1px solid"
                    borderColor="#DADADA"
                    pb="10px"
                >
                    Eventos del Grupo
                </Text>

                <Flex
                    direction="column"
                    gap="20px"
                    position="relative"
                >
                    {cronograma?.map((step: EventsInt, index: number) => (
                        <>
                            {step?.tipo === EventsTypes.ENVIADO && <Enviados step={step} key={index} />}
                            {step?.tipo === EventsTypes.PENDIENTE && <Pendientes step={step} key={index} />}
                            {step?.tipo === EventsTypes.TAREA && <Tareas step={step} key={index} />}
                        </>
                    ))}
                </Flex>
            </Flex >
    );
};