import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import * as Ably from 'ably';
import { AblyProvider } from 'ably/react';
import './css/styles.css'
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const client = new Ably.Realtime({ key: import.meta.env.VITE_ABLY_API_KEY });

if (import.meta.env.VITE_SENTRY_APIKEY)

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_APIKEY,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^https:\/\/.*\.procademy\.es/,
      /^https:\/\/.*\.admin\.proacademy\.app/
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

// registerLicense(import.meta.env.VITE_SYNCFUSION_LICENSE);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <AblyProvider client={client}>
    <App />
  </AblyProvider>
)
