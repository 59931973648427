import { useEffect, useState } from "react";
import { LeccionInt } from "../../../../../interfaces/CursosInt";
import { ComponenteLeccionEnum } from "../../../../../shared/utils/Types/LeccionTipoEnum";
import { categorizarLeccion } from "../../../../../shared/utils/functions/categorizarLeccion";
import { InformationBox } from "../../../../../shared/components/CustomElements/InformationBox/InformationBox";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { PDFViewer } from "../../../../../shared/components/PreviewComponents/PDFViewer";
import { ImagenViewer } from "../../../../../shared/components/PreviewComponents/Imagen";
import { EmbebidoViewer } from "../../../../../shared/components/PreviewComponents/Embebido";
import { VideoViewer } from "../../../../../shared/components/PreviewComponents/VideoViewer";
import sesionesBg from "../../../../../assets/img/bgSesiones.png"
import { formatTimezone } from "../../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../../shared/hooks/useTimeZone";
import { useDataId } from "../../../../../shared/hooks/useDataId";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { useClient } from "../../../../../shared/context/client.context";
import { useAuthContex } from "../../../../../shared/context/auth.context";
import { UserRolEnum } from "../../../../../shared/utils/Types/UserRolEnum";

interface Props {
    leccion: LeccionInt | undefined;
    isModal?: boolean;
}

export const PreviewLeccion = ({ leccion, isModal = false }: Props) => {
    const { user } = useAuthContex();
    const client = useClient();

    const { data } = useDataId({
        endpoint: EndpointTypes.LECCIONES,
        id: leccion?.id,
        client: client,
        ignoreRequest: user?.role?.nombre !== UserRolEnum.ADMIN
    })

    const timeZone = useTimeZone();
    const [componentType, setComponentType] = useState<ComponenteLeccionEnum>()

    useEffect(() => {
        const componente = categorizarLeccion(leccion)
        setComponentType(componente)
    }, [leccion])

    return (
        <>
            {(componentType === ComponenteLeccionEnum.PDF_VIEWER && leccion?.contenido) &&
                <InformationBox
                    noHeader
                >
                    <Flex
                        py="20px"
                        px="20px"
                    >
                        <PDFViewer
                            url={leccion?.contenido}
                        />
                    </Flex>
                </InformationBox>
            }

            {(componentType === ComponenteLeccionEnum.VIDEO && leccion?.contenido) &&
                <InformationBox
                    noHeader
                >
                    <VideoViewer
                        leccion={leccion}
                    />
                </InformationBox>
            }

            {(componentType === ComponenteLeccionEnum.IFRAME && leccion?.contenido) &&
                <InformationBox
                    noHeader
                >
                    <EmbebidoViewer
                        isModal={isModal}
                        leccion={leccion}
                    />
                </InformationBox>
            }

            {(componentType === ComponenteLeccionEnum.IMAGEN && leccion?.contenido) &&
                <InformationBox
                    noHeader
                >
                    <ImagenViewer
                        leccion={leccion}
                        isModal={isModal}
                    />
                </InformationBox>
            }
            {/* 
            {(componentType === ComponenteLeccionEnum.MARKDOWN_PREVIEW && leccion?.contenido) &&
                <InformationBox
                    noHeader
                >
                    <Flex
                        w="100%"
                        py="20px"
                        px="20px"
                    >
                        <Flex
                            w="100%"
                            alignItems={"start"}
                            justifyContent={"center"}
                            overflow="auto"
                            className="scroll-bar"
                            h="auto"
                            maxH={!isModal ? "700px" : "550px"}
                        >
                            <MarkdownPreview
                                source={leccion?.contenido || ''}
                                style={{
                                    padding: "20px",
                                    fontSize: '15px',
                                    width: '100%',
                                    maxWidth: '100%',
                                    backgroundColor: "transparent"
                                }}
                                wrapperElement={{
                                    "data-color-mode": "light"
                                }}
                            />
                        </Flex>
                    </Flex>
                </InformationBox>
            } */}

            {(componentType === ComponenteLeccionEnum.LINK && leccion?.contenido) &&
                <InformationBox
                    noHeader
                >
                    <Flex
                        py="20px"
                        px="20px"
                        direction="column"
                        gap="20px"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Text
                            fontSize="18px"
                            fontWeight="500"
                            color="pureblack"
                        >
                            Haz click en el siguiente botón para acceder al recurso
                        </Text>

                        <Button
                            px="10px"
                            py="10px"
                            h="50px"
                            w="30%"
                            gap="10px"
                            rounded="5px"
                            border="1px solid"
                            borderColor={"secondary"}
                            fontSize="14px"
                            fontWeight={"500"}
                            color="purewhite"
                            bg="secondary"
                            _hover={{ bg: "variant" }}
                            isDisabled={!leccion?.contenido || leccion?.contenido === ""}
                            onClick={() => {
                                if (leccion?.contenido)
                                    window.open(leccion?.contenido, "_blank")
                            }}
                        >
                            Ir al recurso
                        </Button>
                    </Flex>
                </InformationBox>
            }

            {(componentType === ComponenteLeccionEnum.SESION_VIVO) &&
                <InformationBox
                    noHeader
                >
                    <Flex
                        direction="column"
                    >
                        <Image
                            src={sesionesBg}
                            h="70px"
                            roundedTopRight="10px"
                            roundedTopLeft="10px"
                        />

                        <Flex
                            justifyContent="space-between"
                            w="100%"
                            px="40px"
                            py="40px"
                        >
                            <Flex
                                direction="column"
                            >
                                {
                                    ((leccion?.sesionVivo?.fechaInicio && leccion?.sesionVivo?.fechaFin) &&
                                        new Date() > new Date(leccion.sesionVivo.fechaInicio) && new Date() < new Date(leccion.sesionVivo.fechaFin)
                                    ) &&
                                    <Flex
                                        alignItems="center"
                                        gap="5px"
                                        mb="20px"
                                    >
                                        <Box
                                            w="17px"
                                            h="17px"
                                            bg="main"
                                            rounded="full"
                                        />

                                        <Text
                                            color="main"
                                            textTransform="uppercase"
                                            fontWeight="600"
                                            fontSize="12px"
                                        >
                                            En directo
                                        </Text>
                                    </Flex>
                                }

                                <Text
                                    fontSize="20px"
                                    fontWeight="700"
                                    color="font"
                                >
                                    {data?.modulo?.curso?.nombre}
                                </Text>

                                <Text
                                    fontSize="18px"
                                    fontWeight="700"
                                    color="font"
                                >
                                    {data?.modulo?.nombre} - {leccion?.nombre}
                                </Text>
                            </Flex>

                            <Flex
                                direction="column"
                                color="font"
                                fontSize="14px"
                                fontWeight="600"
                                letterSpacing="-0.09px"
                                alignItems="end"
                                textTransform="uppercase"
                            >
                                <Text
                                    alignItems="end"
                                >
                                    {formatTimezone({ date: leccion?.sesionVivo?.fechaInicio, timeZone: timeZone, customFormat: "dd 'de' MMMM 'de' yyyy" })}
                                </Text>

                                <Flex
                                    alignItems="center"
                                    gap="5px"
                                >
                                    <Text>
                                        {formatTimezone({ date: leccion?.sesionVivo?.fechaInicio, timeZone: timeZone, customFormat: "HH:mm" })}
                                    </Text>

                                    <Text>
                                        -
                                    </Text>

                                    <Text>
                                        {formatTimezone({ date: leccion?.sesionVivo?.fechaFin, timeZone: timeZone, customFormat: "HH:mm" })}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>

                        <Box h="1px" bg="#E6E6EA" w="90%" mx="auto" />

                        <Text
                            px="40px"
                            py="40px"
                            fontSize="15px"
                            fontWeight="400"
                            lineHeight="150%"
                        >
                            {leccion?.descripcion}
                        </Text>
                    </Flex>
                </InformationBox >
            }
        </>
    )
}
