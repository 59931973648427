import { Flex, Box, Icon, Text, AvatarGroup } from "@chakra-ui/react"
import { formatTimezone } from "../../../../shared/utils/functions/parseDate"
import { useTimeZone } from "../../../../shared/hooks/useTimeZone"
import { LuMailCheck } from "react-icons/lu"
import { EventsInt } from "../../views/Grupos/Information/TabItems/TabEventos"
import { IoIosArrowDropright } from "react-icons/io"
import { CustomAvatar } from "../../../../shared/components/Avatar/CustomAvatar"
import { boolean } from "yup"
import { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { IoMailUnreadOutline } from "react-icons/io5"

interface Props {
    step: EventsInt;
}

export const Pendientes = ({ step }: Props) => {
    const [openData, setOpenData] = useState<boolean>(false);
    const timeZone = useTimeZone();

    return (
        <Flex
            gap="20px"
            position="relative"
        >
            <Flex
                direction="column"
                alignItems="center"
                position="relative"
            >
                <Box
                    w={"1px"}
                    bg="#DADADA"
                    h="full"
                    position="absolute"
                    top="25px"
                />

                <Box
                    w="8px"
                    h="8px"
                    bg="main"
                    rounded="full"
                    position="absolute"
                    top="6px"
                    zIndex="1"
                />

                <Box
                    w={"1px"}
                    bg="#DADADA"
                    h="full"
                    position="absolute"
                    top="25px"
                />

                <Flex
                    boxSize="50px"
                    bg="main"
                    rounded="full"
                    alignItems="center"
                    justifyContent="center"
                    position="relative"
                    zIndex="1"
                    mt={"40px"}
                >
                    <Icon as={IoMailUnreadOutline} boxSize="28px" color="purewhite" />
                </Flex>
            </Flex>

            <Flex
                direction="column"
                flex="1"
            >
                <Text
                    textTransform={"uppercase"}
                    color="#545763"
                    fontWeight={"400"}
                    fontSize="14px"
                    mb="35px"
                >
                    {formatTimezone({ date: step?.fecha, timeZone, customFormat: "dd 'de' MMMM, yyyy" })}
                </Text>

                <Flex
                    alignItems="start"
                    gap="10px"
                >
                    <motion.div
                        initial={false}
                        animate={{ rotate: openData ? 90 : 0 }}
                        transition={{ duration: 0.2 }}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            transformOrigin: 'center',
                            cursor: 'pointer'
                        }}
                    >
                        <Icon
                            as={IoIosArrowDropright}
                            boxSize="20px"
                            onClick={() => setOpenData(prev => !prev)}
                        />
                    </motion.div>

                    <Flex
                        alignItems="center"
                        gap="10px"
                        mb="16px"
                    >
                        <Text
                            fontSize="14px"
                        >
                            Se han {" "}
                            <Text as="span" fontWeight="700" textDecoration={"underline"}>programado</Text>
                            {" "} {step?.cantidad} emails con fecha de envío {" "}
                            <Text as="span" fontWeight="700">{formatTimezone({ date: step?.fecha, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                        </Text>

                        <Box
                            boxSize="4px"
                            bg={"#D9D9D9"}
                            rounded="full"
                        />

                        <Text
                            color={"#B3B3B3"}
                            fontSize="14px"
                            fontWeight="400"
                            textTransform="uppercase"
                        >
                            {formatTimezone({ date: step?.fecha, timeZone, customFormat: "hh:mm" })}
                        </Text>
                    </Flex>
                </Flex>

                <AnimatePresence>
                    {openData && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.2 }}
                            style={{ overflow: "hidden" }}
                        >
                            <Flex
                                bg="purewhite"
                                border="1px solid"
                                borderColor="#E2E8F0"
                                rounded="20px"
                                direction="column"
                            >
                                <Flex
                                    px="20px"
                                    py="10px"
                                    w="100%"
                                    bg={"#F2F2F2"}
                                    roundedTop={"20px"}
                                    color={"#959595"}
                                    textTransform={"uppercase"}
                                    fontSize="14px"
                                    fontWeight="500"
                                >
                                    <Text
                                        flex="1"
                                    >
                                        Nombre
                                    </Text>

                                    <Text
                                        flex="1"
                                    >
                                        Asunto
                                    </Text>
                                </Flex>

                                {step?.usuarios?.map((user, index) => (
                                    <Flex
                                        key={index}
                                        px="20px"
                                        py="10px"
                                        w="100%"
                                        fontSize="14px"
                                        fontWeight="400"
                                        alignItems="center"
                                    >
                                        <Flex
                                            flex="1"
                                            alignItems="center"
                                            gap="10px"
                                        >
                                            <CustomAvatar
                                                name={user?.usuario}
                                                size="40px"
                                                showLetter
                                            />
                                            <Text>
                                                {user?.usuario}
                                            </Text>
                                        </Flex>

                                        <Text
                                            flex="1"
                                        >
                                            {user?.asunto}
                                        </Text>
                                    </Flex>
                                ))}
                            </Flex>
                        </motion.div>
                    )}
                </AnimatePresence>
            </Flex>
        </Flex>
    )
}
