import { Modal, ModalOverlay, ModalContent, ModalBody, Text, ModalFooter, Button } from "@chakra-ui/react";
import { FormTextarea } from "@imaginagroup/bit-components.ui.form-textarea";
import { Formik, Form as FormikForm } from "formik";
import { PreguntaInt, RespuestaInt } from "../../../../interfaces/ExamenInt";
import { QueryObserverResult } from "@tanstack/react-query";
import { SetStateAction, useState } from "react";
import { Dispatch } from "react";
import { useClient } from "../../../../shared/context/client.context";
import { addRespuesta, updateRespuesta, deleteRespuesta } from "../../../../shared/middlewares/examenes.middleware";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    respuestas: RespuestaInt[] | undefined;
    preguntaId: string | undefined;
    refreshData: () => Promise<QueryObserverResult>;
    setSelected: Dispatch<SetStateAction<PreguntaInt | undefined>>;
}

export const EditRespuestasModal = ({
    isOpen,
    onClose,
    respuestas,
    preguntaId,
    refreshData,
    setSelected
}: Props) => {
    const client = useClient();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const initialValues = {
        respuestas: respuestas?.map((respuesta) => respuesta?.contenido).join('\n')
    }

    const handleSubmit = async (values: any) => {
        setIsLoading(true)

        const respuestasFormak = values?.respuestas?.split('\n').map((respuesta: string, index: number) => {
            if (respuestas?.[index]) return { id: respuestas?.[index]?.id, contenido: respuesta, publicado: true }
            else return { contenido: respuesta, correcta: false, preguntaId, publicado: true }
        })

        const respuestasEliminadas = respuestas?.filter(
            (respuestaOriginal) => !respuestasFormak.some(
                (nuevaRespuesta: RespuestaInt) => nuevaRespuesta?.id === respuestaOriginal?.id
            )
        )

        if (respuestasEliminadas?.length) {
            await Promise.all(
                respuestasEliminadas.map(async (respuesta) => {
                    await deleteRespuesta({ respuestaId: respuesta?.id as string, client })
                        .then(() => {
                            setSelected((prev: PreguntaInt | undefined) => {
                                if (!prev) return undefined;

                                return {
                                    ...prev, respuestas: prev?.respuestas?.filter((r: RespuestaInt) => r?.id !== respuesta?.id)
                                }
                            });
                        })
                })
            )
        }

        await respuestasFormak.forEach(
            async (
                respuesta: {
                    id?: string | undefined;
                    contenido: string;
                    correcta?: boolean | undefined;
                    preguntaId: string | undefined;
                    publicado: boolean | undefined
                }
            ) => {
                if (respuesta?.id && !respuesta?.preguntaId) {
                    await updateRespuesta({ id: respuesta?.id, data: { contenido: respuesta?.contenido, publicado: true }, client: client })


                    setSelected((prev: PreguntaInt | undefined) => {
                        if (!prev) return undefined;

                        return {
                            ...prev,
                            respuestas: prev?.respuestas?.map((r: RespuestaInt) => {
                                if (r?.id === respuesta?.id) return { ...respuesta, contenido: respuesta?.contenido }

                                return r
                            })
                        }
                    });
                }

                if (!respuesta?.id && respuesta?.preguntaId) {
                    await addRespuesta({ respuesta: respuesta, client: client })
                        .then((response) => {
                            const newRespuesta = response?.data?.data;

                            setSelected((prev: PreguntaInt | undefined) => {
                                if (!prev) return undefined;

                                return {
                                    ...prev,
                                    respuestas: [...(prev?.respuestas || []), newRespuesta]
                                }
                            });
                        })
                }
            }
        )

        await refreshData()
        onClose()
        setIsLoading(false)
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                borderRadius="10px"
                p="32px"
            >
                <Formik
                    onSubmit={handleSubmit}
                    enableReinitialize
                    initialValues={initialValues}
                >
                    {(formik) => {
                        const { handleSubmit, values } = formik;

                        return (
                            <FormikForm
                                onSubmit={handleSubmit}
                            >
                                <ModalBody
                                    p="0"
                                >
                                    <Text
                                        color="font"
                                        fontSize="24px"
                                        fontWeight="500"
                                    >
                                        Editar respuestas
                                    </Text>

                                    <Text
                                        mt="16px"
                                        mb="24px"
                                        color="font"
                                        fontSize="14px"
                                        fontWeight="400"
                                    >
                                        Escribe o pega tus opciones a continuación. Cada opción debe ir en una línea separada.
                                    </Text>

                                    <FormTextarea
                                        noHead
                                        name="respuestas"
                                        ui={{
                                            minHeigh: '320px',
                                            styles: {
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                borderRadius: "5px",
                                                border: "1px solid",
                                                borderColor: "#EAEAEA",
                                                padding: "5px 11px"
                                            },
                                        }}
                                    />
                                </ModalBody>

                                <ModalFooter
                                    justifyContent="end"
                                    gap="15px"
                                    mt="24px"
                                    p="0"
                                >
                                    <Button
                                        type="submit"
                                        bg="transparent"
                                        p="10px 15px"
                                        rounded="5"
                                        borderWidth="1px"
                                        borderColor="main"
                                        color="main"
                                        fontSize="14px"
                                        fontWeight="500"
                                        isDisabled={isLoading}
                                        onClick={() => {
                                            onClose();
                                        }}
                                    >
                                        Cancelar
                                    </Button>

                                    <Button
                                        type="submit"
                                        p="10px 15px"
                                        bg="main"
                                        _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                        rounded="5"
                                        color="purewhite"
                                        fontSize="14px"
                                        fontWeight="500"
                                        isLoading={isLoading}
                                        isDisabled={values?.respuestas === respuestas?.map((respuesta) => respuesta?.contenido).join('\n')}
                                    >
                                        Guardar respuestas
                                    </Button>
                                </ModalFooter>
                            </FormikForm>
                        );
                    }}
                </Formik>
            </ModalContent>
        </Modal>
    )
}