import { Modal, ModalOverlay, ModalContent, ModalBody, Text, ModalFooter, Button, Box, ModalHeader, ModalCloseButton, Flex, useToast, Checkbox } from "@chakra-ui/react";
import { MutableRefObject, useState } from "react";
import { ExtraFundaeInt, UserInt } from "../../../../interfaces/UserInt";
import { Formik, Form as FormikForm } from "formik";
import * as Yup from "yup";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { formSelectStyles } from "../../../../ui/formSelectStyles";
import { NivelEstudiosOptions } from "../../../../shared/utils/Types/TipoNivelEstudios";
import { CategoriaProfesionalOptions } from "../../../../shared/utils/Types/TipoCategoriaProfesional";
import { FormInput } from "@imaginagroup/bit-components.ui.form-input";
import { updateUsuario } from "../../../../shared/middlewares/users.middleware";
import { useClient } from "../../../../shared/context/client.context";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    alumnoRef: MutableRefObject<UserInt | null>;
    handleSelectAlumno: (alumno: any) => void;
}

export const DataFundaeAlumnoModal = ({ isOpen, onClose, alumnoRef, handleSelectAlumno }: Props) => {
    const client = useClient();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const initialValues = {
        extraFundae: null,
    };

    const validationSchema = Yup.object({
        extraFundae: Yup.object().shape({
            nivelEstudios: Yup.string()
                .required('El nivel de estudios es obligatorio.')
                .typeError('El nivel de estudios es obligatorio.'),
            categoriaProfesional: Yup.string()
                .required('La categoría profesional es obligatoria.')
                .typeError('La categoría profesional es obligatoria.'),
            costeHora: Yup.number()
                .required('El coste por hora es obligatorio.')
                .typeError('El coste por hora es obligatorio.'),
            fijoDiscontinuo: Yup.boolean().notRequired().default(false)
        })
    })

    const handleSubmit = (values: any) => {
        setIsLoading(true)

        updateUsuario({
            id: alumnoRef.current?.id as string,
            data: {
                extraFundae: {
                    nivelEstudios: values.extraFundae?.nivelEstudios,
                    categoriaProfesional: values.extraFundae?.categoriaProfesional,
                    costeHora: values.extraFundae?.costeHora,
                    fijoDiscontinuo: values.extraFundae?.fijoDiscontinuo || false
                }
            },
            client: client
        })
            .then((response: any) => {

                const alumno = response?.data?.data
                handleSelectAlumno({ value: alumno, label: `${alumno?.nombre} ${alumno?.apellidos}` })

                alumnoRef.current = null
                onClose();
            })
            .catch((error: any) => {
                console.log(error)
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="lg"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                maxH="95vh"
                rounded="20px"
            >
                <Box
                    overflowY="auto"
                    __css={{
                        '&::-webkit-scrollbar': {
                            w: '7px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '20px',
                            bg: `light_grey`,
                        },
                    }}
                >
                    <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                        {(formik) => {
                            const { handleSubmit, values } = formik;

                            return (
                                <FormikForm
                                    onSubmit={handleSubmit}
                                >
                                    <ModalBody
                                        p="30px"
                                    >
                                        <Flex
                                            direction="column"
                                            gap="10px"
                                        >
                                            <Text
                                                color="black"
                                                fontSize="18px"
                                                fontWeight="700"
                                            >
                                                Datos FUNDAE
                                            </Text>

                                            <Text
                                                color="main"
                                                fontSize="14px"
                                                fontWeight="400"
                                                mb="10px"

                                            >
                                                * Debes completar los datos FUNDAE para poder matricular al alumno.
                                            </Text>

                                            <Flex
                                                direction="column"
                                                gap="10px"
                                            >
                                                <FormSelect
                                                    isRequired={values?.extraFundae && (values?.extraFundae as ExtraFundaeInt)?.nivelEstudios ? false : true}
                                                    name="extraFundae.nivelEstudios"
                                                    label="Nivel de estudios"
                                                    placeholder="Nivel de estudios del alumno"
                                                    options={NivelEstudiosOptions}
                                                    ui={{
                                                        formSelectStyles: formSelectStyles,
                                                        styleLabel: {
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            color: "black"
                                                        }
                                                    }}
                                                />

                                                <FormSelect
                                                    isRequired={values?.extraFundae && (values?.extraFundae as ExtraFundaeInt)?.categoriaProfesional ? false : true}
                                                    name="extraFundae.categoriaProfesional"
                                                    label="Categoría profesional"
                                                    placeholder="Categoría profesional del alumno"
                                                    options={CategoriaProfesionalOptions}
                                                    ui={{
                                                        formSelectStyles: formSelectStyles,
                                                        styleLabel: {
                                                            fontSize: "14px",
                                                            fontWeight: "400",
                                                            color: "black"
                                                        }
                                                    }}
                                                />

                                                <FormInput
                                                    isRequired={values?.extraFundae && (values?.extraFundae as ExtraFundaeInt)?.costeHora ? false : true}
                                                    name="extraFundae.costeHora"
                                                    label="Coste por hora"
                                                    placeholder="Coste por hora del alumno"
                                                    type="number"
                                                    min={0}
                                                />

                                                <Checkbox
                                                    sx={{
                                                        'span.chakra-checkbox__label': {
                                                            marginInlineStart: '0',
                                                            fontSize: '14px',
                                                            fontWeight: '400',
                                                            color: 'black'
                                                        }
                                                    }}
                                                    flexDirection={"row-reverse"}
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    isChecked={(values?.extraFundae as any)?.fijoDiscontinuo}
                                                    onChange={() => formik.setFieldValue("extraFundae.fijoDiscontinuo", !(values?.extraFundae as any)?.fijoDiscontinuo)}
                                                >
                                                    Fijo discontinuo
                                                </Checkbox>
                                            </Flex>
                                        </Flex>
                                    </ModalBody>

                                    <ModalFooter
                                        justifyContent="center"
                                        gap="20px"
                                    >
                                        <Button
                                            isLoading={isLoading}
                                            type="submit"
                                            bg="secondary"
                                            p="10px 25px"
                                            h="fit-content"
                                            border="1px solid"
                                            borderColor="secondary"
                                            rounded="5"
                                            color="purewhite"
                                            fontSize="18px"
                                            fontWeight="500"
                                            _hover={{ color: "purewhite", backgroundColor: "variant", borderColor: "variant" }}
                                        >
                                            Editar
                                        </Button>

                                        <Button
                                            bg="transparent"
                                            p="10px 25px"
                                            h="fit-content"
                                            rounded="5"
                                            color="secondary"
                                            border="1px solid"
                                            borderColor="secondary"
                                            fontSize="18px"
                                            fontWeight="500"
                                            _hover={{ color: "purewhite", backgroundColor: "variant", borderColor: "variant" }}
                                            onClick={(e) => {
                                                e.stopPropagation()

                                                alumnoRef.current = null
                                                onClose()
                                            }}
                                        >
                                            Cancelar
                                        </Button>
                                    </ModalFooter>
                                </FormikForm>
                            );
                        }}
                    </Formik>
                </Box>
            </ModalContent>
        </Modal>
    )
}