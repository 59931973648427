import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Flex, ModalFooter, Button, useToast, Box, ModalCloseButton } from "@chakra-ui/react";
import { Form as FormikForm, Formik } from 'formik';
import * as Yup from "yup"
import { useState } from "react";
import { useClient } from "../../../../shared/context/client.context";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes";
import { addInspectorCurso } from "../../../../shared/middlewares/inspectores.middleware";
import { useData } from "../../../../shared/hooks/useData";
import { FormSelect } from "@imaginagroup/bit-components.ui.form-select";
import { EmpresasInt } from "../../../../interfaces/EmpresasInt";
import { useAuthContex } from "../../../../shared/context/auth.context";
import { UserRolEnum } from "../../../../shared/utils/Types/UserRolEnum";
import { useUserPolicy } from "../../../../shared/hooks/PermissionPolicy";
import { formSelectStyles } from "../../../../ui/formSelectStyles";

interface Props {
    isOpen: boolean;
    cursoId: string | undefined;
    onClose: () => void;
    Refresh: () => void;
    inspectores: any[] | undefined;
}
export const EmpresaInspectorModal = ({
    isOpen,
    onClose,
    cursoId,
    inspectores,
    Refresh,
}: Props) => {
    const { user } = useAuthContex();
    const { gestion, contenido } = useUserPolicy();
    const client = useClient();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { data: empresas } = useData({
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            interna: "true",
        },
        ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    })

    const initialValues = {
        empresaOrganizadoraId: null,
    };

    const validationSchema = Yup.object({
        empresaOrganizadoraId: Yup.string()
            .required('Debe seleccionar una empresa organizadora')
            .typeError('Debe seleccionar una empresa organizadora'),

    });

    const handleSubmit = async (values: any) => {
        if (!cursoId) return;
        setIsLoading(true)

        const inspectorCurso: {
            cursoId: string | undefined;
            empresaId: string;
        } = {
            cursoId: cursoId,
            empresaId: values.empresaOrganizadoraId,
        }

        if (inspectores?.find((inspector: any) => inspector?.meta?.pivot_empresa_id === values?.empresaOrganizadoraId)) {
            toastNotify(toast, StatusEnumTypes.INFO, "La empresa ya tiene un inspector asignado")
            setIsLoading(false)

            return
        }

        addInspectorCurso({
            client: client,
            data: inspectorCurso
        })
            .then(() => Refresh())
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setIsLoading(false)
                onClose()
            })
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => null}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            size="xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent
                maxH="95vh"
                rounded="20px"
            >
                <Box
                    color="black"
                >
                    <ModalHeader
                        textAlign="start"
                        fontSize="24px"
                        fontWeight="600"
                        px="30px"
                        pt="30px"
                        pb="0px"
                    >
                        Asignar inspector al curso
                    </ModalHeader>

                    <ModalCloseButton onClick={onClose} />
                </Box>

                <Box
                    m="10px"
                    overflowY="auto"
                    overflowX="hidden"
                    __css={{
                        '&::-webkit-scrollbar': {
                            w: '7px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '20px',
                            bg: `light_grey`,
                        },
                    }}
                >
                    <Formik onSubmit={handleSubmit} enableReinitialize initialValues={initialValues} validationSchema={validationSchema}>
                        {(formik) => {
                            const { handleSubmit, values } = formik;

                            return (
                                <FormikForm
                                    onSubmit={handleSubmit}
                                >
                                    <ModalBody>
                                        <Flex direction="column" gap="15px" mt="15px">
                                            <FormSelect
                                                options={
                                                    (empresas === undefined || empresas === null)
                                                        ? []
                                                        : [...empresas?.data?.map((empresa: EmpresasInt) => ({ value: empresa?.id, label: empresa?.nombre }))]
                                                }
                                                name="empresaOrganizadoraId"
                                                label="Empresa organizadora asociada"
                                                placeholder="Empresa asociada al inspector"
                                                isRequired={!values.empresaOrganizadoraId ? true : false}
                                                ui={{
                                                    formSelectStyles: formSelectStyles
                                                }}
                                            />
                                        </Flex>
                                    </ModalBody>

                                    <ModalFooter justifyContent="center">
                                        <Button
                                            isLoading={isLoading}
                                            type="submit"
                                            bg="secondary"
                                            p="10px 25px"
                                            h="fit-content"
                                            rounded="5"
                                            color="purewhite"
                                            fontSize="18px"
                                            fontWeight="500"
                                            _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                        >
                                            Crear inspector
                                        </Button>
                                    </ModalFooter>
                                </FormikForm>
                            );
                        }}
                    </Formik>
                </Box>
            </ModalContent>
        </Modal>
    )
}