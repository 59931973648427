import { Box, Flex, Icon, Link, PlacementWithLogical, StyleProps, Text } from "@chakra-ui/react"
import { Route, Routes, useLocation } from "react-router-dom"
import { TabsRouterInt } from "../../../interfaces/ComponentsInt"
import { CustomButton } from "../CustomElements/CustomButton";
import { Link as RouterLink } from "react-router-dom";
import { BsThreeDots } from "react-icons/bs";
import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

interface ButtonInt {
    isAllowed: boolean;
    paths: string[];
    tooltip?: {
        label: string;
        hasArrow?: boolean;
        isDisabled: boolean;
        placement?: PlacementWithLogical;
    }
    button: {
        label?: string;
        isDisabled: boolean;
        onClick: (event?: React.MouseEvent<HTMLElement>) => void;
        isLoading?: boolean;
        leftIcon?: {
            as: any;
            boxSize: string | number;
        };
        rightIcon?: {
            as: any;
            boxSize: string | number;
        }
    }
    styles?: StyleProps;
}

interface Props {
    tabs: TabsRouterInt[];
    buttons?: ButtonInt[];
    maxOptions?: number;
}

const dropdownVariants = {
    hidden: {
        opacity: 0,
        y: -10,
        scale: 0.95
    },
    visible: {
        opacity: 1,
        y: 0,
        scale: 1,
        transition: {
            duration: 0.2,
            ease: "easeOut"
        }
    },
    exit: {
        opacity: 0,
        y: -10,
        scale: 0.95,
        transition: {
            duration: 0.15
        }
    }
};

export const TabsRouter = ({
    tabs,
    buttons,
    maxOptions = 6,
}: Props) => {
    const location = useLocation();
    const modalRef = useRef<HTMLDivElement | null>(null);
    const [showMore, setShowMore] = useState<boolean>(false)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {

                setShowMore(false);
            }
        };

        document.addEventListener('click', handleClickOutside);


        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <Flex
            direction="column"
            gap="10px"
            w="100%"
            h="fit-content"
        >
            <Flex
                bg={"purewhite"}
                fontSize="16px"
                fontWeight="400"
                flex="1"
                gap="20px"
                p="14px"
                border={"1px solid"}
                borderColor={"#EAEAEA"}
                rounded="14px"
                display={"flex"}
                alignItems="center"
                justifyContent="space-between"
            >
                <Flex
                    w="100%"
                    alignItems="center"
                    gap="5px"
                >
                    {tabs?.filter((t) => t?.isAllowed)?.map(({ label, icon, path }, index) => (
                        index < maxOptions &&
                        <Link
                            key={index}
                            as={RouterLink}
                            to={path}
                            display={"flex"}
                            gap="10px"
                            alignItems="center"
                            rounded="8px"
                            _hover={{ color: "main", bg: "variant" }}
                            border={"2px solid"}
                            borderColor={location.pathname.endsWith(path) ? "main" : "transparent"}
                            color={location.pathname.endsWith(path) ? "main" : "font"}
                            bg={location.pathname.endsWith(path) ? "variant" : "transparent"}
                            _selected={{ color: "main", border: "2px solid", borderColor: "main", bg: "variant" }}
                            py="8px"
                            px="16px"
                        >
                            <Icon
                                as={icon}
                                boxSize="22px"
                            />

                            {label}
                        </Link>
                    ))}

                    {tabs?.filter((t) => t?.isAllowed)?.length > maxOptions && (
                        <Box
                            pos="relative"
                            mt="auto"
                            cursor="pointer"
                            onClick={() => setShowMore((prev) => !prev)}
                            ref={modalRef}
                        >
                            <motion.div
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <Icon as={BsThreeDots} boxSize="22px" />
                            </motion.div>

                            <AnimatePresence>
                                {showMore && (
                                    <motion.div
                                        variants={dropdownVariants}
                                        initial="hidden"
                                        animate="visible"
                                        exit="exit"
                                        style={{
                                            position: 'absolute',
                                            top: '20px',
                                            left: '20px',
                                            minWidth: '300px',
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: '6px',
                                            boxShadow: '0px 4px 15px 0px rgba(199, 205, 255, 0.60)',
                                            zIndex: 999,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px',
                                            padding: '10px',
                                        }}
                                    >
                                        {tabs?.map(({ icon, path, label }: TabsRouterInt, index: number) => (
                                            index >= maxOptions &&
                                            <Link
                                                key={index}
                                                as={RouterLink}
                                                to={path}
                                                display={"flex"}
                                                gap="10px"
                                                alignItems="center"
                                                justifyContent={"center"}
                                                rounded="8px"
                                                _hover={{ color: "main", bg: "variant" }}
                                                border={"2px solid"}
                                                borderColor={location.pathname.includes(path) ? "main" : "transparent"}
                                                color={location.pathname.includes(path) ? "main" : "font"}
                                                bg={location.pathname.includes(path) ? "variant" : "transparent"}
                                                _selected={{ color: "main", border: "2px solid", borderColor: "main", bg: "variant" }}
                                                py="8px"
                                                px="16px"
                                            >
                                                <Icon
                                                    as={icon}
                                                    boxSize="22px"
                                                />

                                                {label}
                                            </Link>
                                        ))}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </Box>
                    )}
                </Flex>

                <Flex
                    gap="20px"
                    display={buttons && buttons?.length > 0 ? "flex" : "none"}
                >
                    {buttons?.map((button: ButtonInt, index: number) => (
                        button?.isAllowed &&
                        (button?.paths?.some((p) => location?.pathname?.includes(p))) &&
                        <CustomButton
                            key={index}
                            {...button}
                        />
                    ))}
                </Flex>
            </Flex>


            <Routes>
                {tabs?.map(({ path, content }, index) => (
                    <Route
                        key={index}
                        path={path}
                        element={content}
                    />
                ))}
            </Routes >
        </Flex>
    )
}