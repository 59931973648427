import { ComponentConfig, DropZone } from "@measured/puck";
import getClassNameFactory from "../../../../../../shared/utils/functions/getClassNameFactory";
import styles from "./styles.module.css";
import { Flex } from "@chakra-ui/react";

const getClassName = getClassNameFactory("EmailRow", styles);

export type EmailRowProps = {
    items: {
        width?: string;
        verticalAlign?: "top" | "middle" | "bottom";
    }[];
    backgroundColor: string;
    spacing: string;
};

export const EmailRow: ComponentConfig<EmailRowProps> = {
    fields: {
        items: {
            type: "array",
            getItemSummary: (item, id = -1) =>
                `Columna ${id + 1}${item.width ? ` (${item.width})` : ''}`,
            arrayFields: {
                width: {
                    type: "select",
                    label: "Ancho",
                    options: [
                        { label: "Auto", value: "" },
                        { label: "20%", value: "20%" },
                        { label: "25%", value: "25%" },
                        { label: "30%", value: "30%" },
                        { label: "33%", value: "33.33%" },
                        { label: "35%", value: "35%" },
                        { label: "40%", value: "40%" },
                        { label: "45%", value: "45%" },
                        { label: "50%", value: "50%" },
                        { label: "60%", value: "60%" },
                        { label: "66%", value: "66.66%" },
                        { label: "75%", value: "75%" },
                    ],
                },
                verticalAlign: {
                    type: "select",
                    label: "Alineación vertical",
                    options: [
                        { label: "Superior", value: "top" },
                        { label: "Medio", value: "middle" },
                        { label: "Inferior", value: "bottom" },
                    ],
                },
            },
        },
        backgroundColor: {
            type: "select",
            label: "Color de fondo",
            options: [
                { label: "Transparente", value: "transparent" },
                { label: "Blanco", value: "#ffffff" },
                { label: "Gris claro", value: "#f8f9fa" },
                { label: "Gris", value: "#e9ecef" },
            ],
        },
        spacing: {
            type: "select",
            label: "Espacio entre columnas",
            options: [
                { label: "0", value: "0" },
                { label: "8px", value: "8px" },
                { label: "16px", value: "16px" },
                { label: "24px", value: "24px" },
                { label: "32px", value: "32px" },
                { label: "40px", value: "40px" },
                { label: "48px", value: "48px" },
                { label: "56px", value: "56px" },
                { label: "64px", value: "64px" },
                { label: "72px", value: "72px" },
                { label: "80px", value: "80px" },
                { label: "88px", value: "88px" },
                { label: "96px", value: "96px" },
                { label: "104px", value: "104px" },
                { label: "112px", value: "112px" },
                { label: "120px", value: "120px" },
                { label: "128px", value: "128px" },
                { label: "136px", value: "136px" },
                { label: "144px", value: "144px" },
                { label: "152px", value: "152px" },
                { label: "160px", value: "160px" },
            ],
        },
    },
    defaultProps: {
        items: [{}, {}],
        backgroundColor: "transparent",
        spacing: "16px",
    },
    render: ({ items, backgroundColor, spacing }) => {
        const calculateWidths = () => {
            const definedWidths = items.filter(item => item.width);
            const totalDefinedWidth = definedWidths.reduce((acc, item) =>
                acc + (parseFloat(item.width!) || 0), 0);

            const remainingItems = items.length - definedWidths.length;
            const remainingWidth = Math.max(0, 100 - totalDefinedWidth);
            const autoWidth = remainingItems > 0 ?
                `${remainingWidth / remainingItems}%` : "100%";

            return items.map(item => item.width || autoWidth);
        };

        const itemWidths = calculateWidths();

        return (
            <table
                className={getClassName()}
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                role="presentation"
                style={{
                    backgroundColor,
                }}
            >
                <tbody>
                    <tr>
                        {items.map((item, idx) => (
                            <td
                                key={idx}
                                className={getClassName("cell")}
                                width={itemWidths[idx]}
                                valign={item.verticalAlign || "top"}
                                style={{
                                    paddingLeft: idx > 0 ? spacing : "0",
                                }}
                            >
                                <DropZone
                                    zone={`row-content-${idx}`}
                                    disallow={["Hero", "Logos", "Stats"]}
                                />
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        );
    },
};
