import { Button, Flex, Icon, useToast } from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect } from "react";
import { BiPlus } from "react-icons/bi";
import { ExamenInt, PreguntaInt } from "../../../../interfaces/ExamenInt";
import { addPregunta } from "../../../../shared/middlewares/examenes.middleware";
import { useClient } from "../../../../shared/context/client.context";
import { handleErrors } from "../../../../shared/utils/functions/handleErrors";
import { toastNotify } from "../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes";
import { PreguntasModule } from "./PreguntasModule";

interface Props {
    selected: PreguntaInt | undefined;
    setSelected: Dispatch<SetStateAction<PreguntaInt | undefined>>;
    examen: ExamenInt;
    refreshData: () => void;
}

export const AccordeonContenidoExamen = ({
    selected,
    setSelected,
    examen,
    refreshData,
}: Props) => {
    const client = useClient();
    const toast = useToast();

    useEffect(() => {
        if (!examen?.preguntas || examen?.preguntas?.length === 0) return;

        if (!selected) {
            setSelected(examen?.preguntas[0]);
        }
    }, [examen])

    const handleAddPreguntas = () => {
        if (!examen?.id) return;

        const contenido = examen?.preguntas ? `Pregunta ${examen?.preguntas?.length + 1}` : "Pregunta";

        const newPregunta = {
            examenId: examen?.id,
            contenido: contenido,
            publicado: true,
        }

        addPregunta({
            pregunta: newPregunta,
            client: client
        })
            .then((response) => {
                const pregunta = response?.data?.data

                refreshData()

                setSelected(pregunta)
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    }

    return (
        <Flex
            pos="relative"
            direction="column"
            w="100%"
        >
            <Flex
                display="flex"
                flexDirection="column"
                gap="15px"
                css={{
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#c7c7c7',
                        borderRadius: '5px',
                    },
                    scrollBehavior: 'smooth',
                }}
                p="5px"
                overflow="auto"
                h="calc(100vh - 215px)"
            >
                {examen?.preguntas?.map((pregunta: PreguntaInt) => (
                    <PreguntasModule
                        pregunta={pregunta}
                        setSelected={setSelected}
                        selected={selected}
                    />
                ))}
            </Flex>

            <Button
                position="absolute"
                bottom="0px"
                h="fit-content"
                w="100%"
                py="10px"
                px="10px"
                rounded={"6px"}
                border="0.6px solid"
                borderColor="#AAA"
                bg="white"
                fontSize="14px"
                color="pureblack"
                fontWeight="400"
                leftIcon={<Icon as={BiPlus} boxSize="18px" color="pureblack" />}
                onClick={(event: React.MouseEvent) => {
                    event.stopPropagation()

                    handleAddPreguntas()
                }}
            >
                Añadir pregunta
            </Button>
        </Flex>
    )
}