import { Button, Flex, Icon, Text, Tooltip } from "@chakra-ui/react";
import { ColumnProps } from "../../../../shared/components/Table/Table";
import { BiMedal } from "react-icons/bi";
import TiempoFormato from "../../../../shared/utils/functions/formatTime";
import { Dispatch, SetStateAction } from "react";
import { ColumnSimpleText } from "@imaginagroup/bit-components.ui.columns.column-simple-text";

interface Props {
    userIdRef: any;
    onOpenIngreso: () => void;
    setMatricula: Dispatch<SetStateAction<any>>;
    onOpenInformes: () => void;
    matriculaIdRef: any;
    onOpenResumen: () => void;
    onClose: () => void;
}

export const InformationColumns = ({
    userIdRef,
    onOpenIngreso,
    setMatricula,
    onOpenInformes,
    matriculaIdRef,
    onOpenResumen,
    onClose
}: Props): ColumnProps[] => {

    const validateRequisitos = (progreso: number, puntuacion: number) => {
        if (!progreso || !puntuacion) return false

        if (progreso >= 75 && puntuacion >= 75) return true
        else return false
    }

    const getHoraProcentaje = (tiempoAcceso: number, duracionCurso: number) => {
        if (!tiempoAcceso || !duracionCurso) return 0

        const tiempo = tiempoAcceso / 3600;

        const porcentaje = (tiempo / duracionCurso) * 100;

        return parseInt(porcentaje.toFixed(0)) <= 100 ? parseInt(porcentaje.toFixed(0)) : 100
    }

    return [
        {
            header: 'nombre',
            field: 'nombre',
            key: 'nombre',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.user?.nombre + " " + rowData?.user?.apellidos}
                    icon={{
                        name: BiMedal,
                        color: validateRequisitos(rowData?.progresoLecciones, rowData?.puntuacionMedia)
                            ? "main"
                            : "dark_grey",
                        size: "20px"
                    }}
                />
            )
        },
        {
            header: '% actividades completadas',
            field: 'progresoLecciones',
            key: 'progresoLecciones',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={parseInt(rowData?.progresoLecciones?.toFixed(0)) + " %"}
                    ui={{
                        colorFont: rowData?.progresoLecciones < 75 ? "fail" : "success_main"
                    }}
                />
            )
        },
        {
            header: '% ejercicios completados',
            field: 'progresoEntregables',
            key: 'progresoEntregables',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.progresoEntregables?.toFixed(0) + " %"}
                    ui={{
                        colorFont: rowData?.progresoEntregables < 75 ? "fail" : "success_main"
                    }}
                />
            )
        },
        {
            header: 'nota media',
            field: 'puntuacionMedia',
            key: 'puntuacionMedia',
            sortable: true,
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.puntuacionMedia?.toFixed(0)}
                    ui={{
                        colorFont: (rowData?.puntuacionMedia || 0) < 75 ? "fail" : "success_main"
                    }}
                />
            )
        },
        {
            header: 'tiempo de acceso (horas/%)',
            field: 'tiempoAcceso',
            key: 'tiempoAcceso',
            sortable: true,
            body: (rowData: any) => (
                <Flex
                    justifyContent="center"
                    color="font"
                    gap="5px"
                >
                    <Text fontWeight="700">
                        <TiempoFormato tiempoAcceso={rowData?.tiempoAcceso} />{' '}
                    </Text>

                    <Text
                        fontSize="16px"
                        fontWeight="400"
                        color="font"
                    >
                        h / {getHoraProcentaje(rowData?.tiempoAcceso, rowData?.grupo?.curso?.duracion)}<Text fontSize="14px" as="span"> %</Text>
                    </Text>


                </Flex>
            )
        },
        {
            header: 'Formulario completado',
            field: 'formularioCompletado',
            key: 'formularioCompletado',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.formularioCompletado ? "Si" : "No"}
                    ui={{
                        fontSize: "14px",
                        styles: {
                            textTransform: "uppercase"
                        }
                    }}
                />
            )
        },
        {
            header: 'Descarga diploma',
            field: 'diplomaDescargado',
            key: 'diplomaDescargado',
            body: (rowData: any) => (
                <ColumnSimpleText
                    text={rowData?.diplomaDescargado ? "Si" : "No"}
                    ui={{
                        fontSize: "14px",
                        styles: {
                            textTransform: "uppercase"
                        }
                    }}
                />
            )
        },
        {
            header: '',
            field: 'ingresoAlumno',
            key: 'ingresoAlumno',
            body: (rowData: any) => (
                <Button
                    mx="8px"
                    my="16px"
                    h="auto"
                    p="7px 12px"
                    bg="black"
                    color="white"
                    fontSize="12px"
                    fontWeight="600px"
                    _hover={{ bg: "light_grey", color: "black" }}
                    onClick={() => {
                        userIdRef.current = rowData?.user
                        onOpenIngreso()
                    }}
                >
                    Ingresar como alumno
                </Button>
            )
        },
        {
            header: '',
            field: 'informes',
            key: 'informes',
            body: (rowData: any) => (
                <Button
                    mx="8px"
                    my="16px"
                    h="fit-content"
                    p="7px 12px"
                    bg="black"
                    color="white"
                    fontSize="12px"
                    fontWeight="600px"
                    _hover={{ bg: "light_grey", color: "black" }}
                    onClick={() => {
                        setMatricula(rowData)
                        onOpenInformes()
                    }}
                >
                    Descargar informes
                </Button>
            )
        },
        {
            header: '',
            field: 'resumen',
            key: 'resumen',
            body: (rowData: any) => (
                <Tooltip
                    label="Ver resumen completo del alumno"
                    hasArrow
                >
                    <Button
                        mr="16px"
                        mx="8px"
                        my="16px"
                        h="fit-content"
                        p="7px 12px"
                        bg="black"
                        color="white"
                        fontSize="12px"
                        fontWeight="600px"
                        _hover={{ bg: "light_grey", color: "black" }}
                        onClick={() => {
                            matriculaIdRef.current = rowData?.id

                            onOpenResumen()
                            onClose()
                        }}
                    >
                        Ver resumen
                    </Button>
                </Tooltip>
            )
        }
    ];
}