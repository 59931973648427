import { Flex } from "@chakra-ui/react"
import { Seccion } from "./BaseSidebar";
import { Extralinks } from "./Extralinks";

interface Props {
    state: boolean;
    extraLinks: Seccion[];
    activePath: (text: string) => boolean;
    setState: (state: boolean) => void;
}

export const SecondarySidebar = ({ state, extraLinks, activePath, setState }: Props) => {
    return (
        <Flex
            h="100vh"
            bg="purewhite"
            w={"240px"}
            direction="column"
            pt="20px"
            pos={"absolute"}
            top="0"
            bottom="0"
            right="-241px"
            zIndex="-1"
            shadow="rgba(119, 119, 119, 0.05) 0px 0px 15px -3px, rgba(119, 119, 119, 0.05) 0px 0px 6px -2px"
            transform={state ? "translateX(0)" : "translateX(-240px)"}
            transition={"all 500ms"}
        >
            <Flex
                direction="column"
            >
                {extraLinks?.map((link: Seccion, indexItem: number) => (
                    link?.isAllowed &&
                    <Extralinks
                        key={indexItem}
                        setState={setState}
                        active={activePath(link.navlink.path)}
                        {...link.navlink}
                    />
                ))}
            </Flex >
        </Flex>
    )
}