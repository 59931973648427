import { Button, Flex, Icon, Skeleton, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { InformationBox } from "../../../../shared/components/CustomElements/InformationBox/InformationBox"
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { LeccionInt, ModulosInt } from "../../../../interfaces/CursosInt"
import { removeModulo, updateModulo } from "../../../../shared/middlewares/modulos.middleware"
import * as Yup from "yup"
import { toastNotify } from "../../../../shared/utils/functions/toastNotify"
import { StatusEnumTypes } from "../../../../shared/utils/Types/StatusEnumTypes"
import { handleErrors } from "../../../../shared/utils/functions/handleErrors"
import { useClient } from "../../../../shared/context/client.context"
import { Form as FormikForm, Formik } from 'formik';
import "../../../../ui/scss/cargaMasiva.scss"
import { FormInput } from "@imaginagroup/bit-components.ui.form-input"
import { FormTextarea } from "@imaginagroup/bit-components.ui.form-textarea"
import { BiTrash } from "react-icons/bi"
import { useTenantInfo } from "../../../../shared/hooks/useTenantInfo"
import { FiEdit3 } from "react-icons/fi"
import { TipoSelect } from "../../views/Information/TabItem/TabContenido"
import { CustomSwitch } from "../../../../shared/components/CustomElements/CustomSwitch"
import { ModalDelete } from "../../../../shared/components/Modals/ModalDelete"
import { FormAsyncSelect } from "@imaginagroup/bit-components.ui.form-async-select"
import { QueryTypes } from "../../../../shared/utils/Types/QueryTypes"
import { EndpointTypes } from "../../../../shared/utils/Types/EndpointTypes"
import { loadData } from "../../../../shared/utils/functions/loadData"
import { formSelectStyles } from "../../../../ui/formSelectStyles"
import { NewExamenModal } from "../../../Examenes/components/Modals/NewExamenModal"

interface Props {
    modulo: ModulosInt | undefined;
    setRefreshModulos: () => Promise<void>;
    setSelected: Dispatch<SetStateAction<{ type: TipoSelect; data: ModulosInt | LeccionInt | null; } | undefined>>;
    loading?: boolean;
}
export const EditModule = ({ modulo, setRefreshModulos, setSelected }: Props) => {
    const client = useClient();
    const toast = useToast();
    const [editName, setEditName] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { handleGetInfo } = useTenantInfo();
    const colores = handleGetInfo({ key: "colores" });
    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        // Resetear el formulario cuando cambie el módulo
        if (formikRef.current) {
            formikRef.current.resetForm({
                values: {
                    nombre: modulo?.nombre,
                    descripcion: modulo?.descripcion,
                    publicado: modulo?.publicado,
                    examenId: modulo?.examenId
                }
            });
        }
    }, [modulo?.id]);

    const formikRef = useRef<any>(null);

    useEffect(() => {
        if (editName) {
            const inputElement: HTMLInputElement | null = document.querySelector('input[name="nombre"]');

            if (inputElement) {
                inputElement.focus();
            }
        }
    }, [editName])

    const initialValues = {
        nombre: modulo?.nombre,
        descripcion: modulo?.descripcion,
        publicado: modulo?.publicado,
        examenId: modulo?.examenId
    }

    const validateSchema = Yup.object().shape({
        descripcion: Yup.string().notRequired().max(255, "La descripción no puede tener más de 255 caracteres")
    });

    const handleSubmit = async (values: any, { resetForm }: any) => {
        setLoading(true);
        let newValue = values

        if (values?.descripcion === "") {
            newValue = {
                ...values,
                descripcion: null
            }
        }

        return updateModulo({
            id: modulo?.id as string,
            data: newValue,
            client: client
        })
            .then(async () => {
                await setRefreshModulos()

                setSelected((prev: { type: TipoSelect; data: ModulosInt | LeccionInt | null; } | undefined) => {
                    if (!prev) return undefined;
                    return {
                        ...prev,
                        data: {
                            ...prev.data,
                            descripcion: newValue?.descripcion,
                            publicado: newValue?.publicado,
                            examenId: newValue?.examenId
                        } as ModulosInt
                    };
                });
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => {
                setLoading(false)
                resetForm()
            })
    }

    const isExistsChanges = (values: {
        nombre: string | undefined;
        descripcion: string | undefined;
        publicado: boolean | undefined;
        examenId: string | undefined;
    }) => {
        if (
            values?.nombre !== modulo?.nombre ||
            values?.descripcion !== modulo?.descripcion ||
            values?.publicado !== modulo?.publicado ||
            values?.examenId !== modulo?.examenId
        ) return true

        return false
    }

    const updateName = async (nombre: string) => {
        if (!modulo?.id) return;

        return updateModulo({
            id: modulo?.id,
            data: {
                nombre: nombre.trim()
            },
            client: client
        })
            .then(() => {
                setRefreshModulos()
                setSelected((prev: { type: TipoSelect; data: ModulosInt | LeccionInt | null; } | undefined) => {
                    if (!prev) return undefined;
                    return {
                        ...prev,
                        data: {
                            ...prev.data,
                            nombre
                        } as ModulosInt
                    };
                });
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
    };

    const onDelete = () => {
        if (!modulo?.id) return

        removeModulo({ id: modulo?.id, client: client })
            .then(() => {
                setRefreshModulos()
                setSelected(undefined)
                toastNotify(toast, StatusEnumTypes.SUCCESS, "El modulo ha sido eliminado")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    const loadExamenes = async (value: string) => await loadData({
        value: value,
        client: client,
        endpoint: EndpointTypes.EXAMENES,
        specificQuery: "?libre=true" as QueryTypes,
        query: QueryTypes.NOMBRE,
    })

    return (
        <Flex
            w="100%"
            direction="column"
        >
            <Formik
                innerRef={formikRef}
                onSubmit={handleSubmit}
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validateSchema}
            >
                {(formik) => {
                    const { handleSubmit, values } = formik;

                    return (
                        <FormikForm
                            onSubmit={handleSubmit}
                        >
                            <Flex
                                justifyContent="space-between"
                                mb="20px"
                            >
                                <Flex
                                    h="40px"
                                    alignItems="center"
                                >
                                    {!editName ?
                                        <Flex
                                            alignItems="center"
                                            gap="10px"
                                            mt="2px"
                                            onClick={() => setEditName(true)}
                                        >
                                            <Text
                                                fontSize="24px"
                                                fontWeight="400"
                                                color="pureblack"
                                                pl="2px"
                                            >
                                                {values?.nombre}
                                            </Text>

                                            <Icon
                                                as={FiEdit3}
                                                boxSize="18px"
                                                color="pureblack"
                                                cursor="pointer"
                                            />
                                        </Flex>
                                        :
                                        <FormInput
                                            name="nombre"
                                            isBlockError
                                            onBlur={() => {
                                                setEditName(false)

                                                if (values?.nombre !== modulo?.nombre) {
                                                    updateName(values?.nombre?.trim() as string)
                                                }
                                            }}
                                            ui={{
                                                styleInput: {
                                                    border: "none",
                                                    bg: "transparent",
                                                    width: "auto",
                                                    fontSize: "24px",
                                                    fontWeight: "400",
                                                    color: "pureblack",
                                                    pl: "2px",
                                                },
                                                focusInput: {
                                                    border: "none",
                                                    boxShadow: `0 0 0 1px ${colores.variant}`,
                                                },
                                            }}
                                        />
                                    }
                                </Flex>

                                <Flex
                                    alignItems="center"
                                    gap="10px"
                                >
                                    <Text
                                        display={!isExistsChanges(values) ? "none" : "block"}
                                        opacity={!isExistsChanges(values) ? 0 : 1}
                                        color="fail"
                                        fontSize="14px"
                                        fontWeight="600"
                                    >
                                        Borrador
                                    </Text>

                                    <Button
                                        display={isExistsChanges(values) ? "none" : "block"}
                                        h="fit-content"
                                        w="fit-content"
                                        py="7.4px"
                                        px="10px"
                                        rounded={"6px"}
                                        bg="secondary"
                                        fontSize="14px"
                                        color="purewhite"
                                        fontWeight="400"
                                        onClick={(e: React.MouseEvent) => {
                                            e.stopPropagation()

                                            if (isOpenDelete) onCloseDelete();
                                            onOpenDelete();
                                        }}
                                    >
                                        <Icon
                                            boxSize="22px"
                                            as={BiTrash}
                                        />
                                    </Button>

                                    <Button
                                        display={!isExistsChanges(values) ? "none" : "block"}
                                        h="fit-content"
                                        w="fit-content"
                                        py="10px"
                                        px="10px"
                                        rounded={"6px"}
                                        bg="secondary"
                                        fontSize="14px"
                                        color="purewhite"
                                        fontWeight="400"
                                        type="submit"
                                        isDisabled={!modulo?.id || !isExistsChanges(values)}
                                        isLoading={loading}
                                    >
                                        Guardar
                                    </Button>
                                </Flex>
                            </Flex>

                            <Flex
                                css={{
                                    '&::-webkit-scrollbar': {
                                        display: 'none',
                                    },
                                    'scrollbarWidth': 'none',
                                    '-ms-overflow-style': 'none',
                                    scrollBehavior: 'smooth',
                                }}
                                overflow="auto"
                                maxH="calc(100vh - 240px)"
                            >
                                <InformationBox
                                    noHeader
                                >
                                    <Flex
                                        direction="column"
                                        py="20px"
                                        px="20px"
                                        gap="20px"
                                    >
                                        <FormTextarea
                                            label="Descripción módulo"
                                            name="descripcion"
                                            ui={{
                                                minHeigh: "100px",
                                                styleLabel: {
                                                    color: "font",
                                                    fontSize: "14px",
                                                    fontWeight: "400",
                                                },
                                                styles: {
                                                    border: "1px solid",
                                                    borderColor: "#E2E8F0",
                                                    borderRadius: "5px",
                                                    color: "font",
                                                    padding: "5px",
                                                    maxHeight: "100px",
                                                }
                                            }}
                                        />

                                        <CustomSwitch
                                            label="Publicado"
                                            isRow={false}
                                            optionLeft="Si"
                                            optionRight="No"
                                            value={formik.values?.publicado}
                                            setValue={() => formik.setFieldValue('publicado', !formik.values.publicado)}
                                            colorSwitch="light_grey"
                                            padding="0"
                                            widthSwitch="100%"
                                            labelStyles={{
                                                color: "font",
                                                fontSize: "14px",
                                                fontWeight: "400",
                                            }}
                                        />

                                        <Flex
                                            gap="10px"
                                            alignItems="center"
                                        >
                                            <FormAsyncSelect
                                                key={modulo?.id}
                                                name="examenId"
                                                label="Examen"
                                                defaultOptions
                                                defaultValue={modulo?.examen ? {
                                                    value: modulo.examen.id,
                                                    label: modulo.examen.nombre
                                                } : undefined}
                                                placeholder="Selecciona un examen para asociar al módulo"
                                                loadOptions={loadExamenes}
                                                ui={{
                                                    formSelectStyles: formSelectStyles,
                                                    styleLabel: {
                                                        color: "font",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                    },
                                                }}
                                            />

                                            <Button
                                                h="40px"
                                                px="15px"
                                                bg="main"
                                                color="purewhite"
                                                fontSize="14px"
                                                fontWeight="400"
                                                _hover={{ bg: "primary_dark" }}
                                                onClick={onOpen}
                                                mt="10px"
                                            >
                                                Crear examen
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </InformationBox>
                            </Flex>
                        </FormikForm>
                    );
                }}
            </Formik>

            <NewExamenModal
                isOpen={isOpen}
                onClose={onClose}
                Refresh={setRefreshModulos}
            />

            <ModalDelete
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                onDelete={onDelete}
                label={"modulo"}
            />
        </Flex>
    );
}