import { Flex } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { TabsInformationGrupos } from "./views/Grupos/Information/TabsInformation";
import { TabsGrupos } from "./views/TabsGrupos";


export const Grupos = () => {
    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Helmet>
                <title>Grupos </title>
            </Helmet>
            <Flex direction="column">
                <Routes>
                    <Route
                        index
                        element={
                            <TabsGrupos />
                        }
                    />

                    <Route
                        path='/:id/*'
                        element={
                            <TabsInformationGrupos />
                        }
                    />
                </Routes>
            </Flex>
        </Flex>
    )
};