import { Badge, Box, Flex, Progress, Text, Link } from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { AlertsGrupo } from "../../../../../../shared/components/CustomElements/AlertsGrupo";
import { Actions } from "../../../../../../shared/components/TableElements/Actions";
import { UserRolEnum } from "../../../../../../shared/utils/Types/UserRolEnum";
import { isRoleAllowed } from "../../../../../../shared/utils/functions/validateRol";
import { useAuthContex } from "../../../../../../shared/context/auth.context";
import { formatTimezone } from "../../../../../../shared/utils/functions/parseDate";
import { useTimeZone } from "../../../../../../shared/hooks/useTimeZone";
import { CustomColumnProps } from "../../../../../../shared/components/Table/CustomGroupTable";

interface Props {
    moduloFUNDAE: boolean;
    contenido: boolean;
    onEventRemove: (event: any) => void;
    onOpen: () => void;
    grupoRef: any;
    actions: boolean;
}

export const GroupGruposColumns = ({
    moduloFUNDAE,
    contenido,
    onEventRemove,
    onOpen,
    grupoRef,
    actions
}: Props): CustomColumnProps[] => {
    const timeZone = useTimeZone();
    const { user } = useAuthContex();
    const location = useLocation();

    const deleteAcctions = (columns: CustomColumnProps[]) => {
        columns?.pop()

        return columns;
    }

    const columns: CustomColumnProps[] = [
        {
            header: 'Duracion',
            field: 'fechaInicio',
            key: 'fechaInicio',
            sortable: true,
            redirect: (id: string) => location.pathname.startsWith("/grupos") ? `/grupos/${id}/configuracion` : `/informes/${id}`,
            body: (rowData: any) => (
                <Flex direction="column" alignItems="flex-start" py="16px" mx="16px">
                    <Badge
                        color={
                            rowData?.estado == 2 ? "font" :
                                rowData?.estado == 1 ? "secondary"
                                    : "success_main"
                        }
                        bg={
                            rowData?.estado == 2 ? "light_grey" :
                                rowData?.estado == 1 ? "variant"
                                    : "success_bg"
                        }
                        borderColor={
                            rowData?.estado == 2 ? "light_grey" :
                                rowData?.estado == 1 ? "variant"
                                    : "success_variant"
                        }
                        borderWidth="1px"
                        fontSize="10px"
                        fontWeight="500"
                        letterSpacing="0.2px"
                        textTransform="uppercase"
                        borderRadius="14px"
                        p="2px 5px"

                    >
                        {
                            rowData?.estado == 2 ? "Inactivo" :
                                rowData?.estado == 1 ? "Próximo"
                                    : "Activo"
                        }
                    </Badge>
                    <Flex
                        alignItems="center"
                        gap="5px"
                        color="font"
                        fontSize="14px"
                        fontWeight="400"
                    >
                        <Text>{formatTimezone({ date: rowData?.fechaInicio, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                        <Text mb="2px">-</Text>
                        <Text>{formatTimezone({ date: rowData?.fechaFin, timeZone, customFormat: "dd/MM/yyyy" })}</Text>
                    </Flex>
                </Flex>
            )
        },
        {
            header: 'Nombre',
            field: 'cursos.nombre',
            key: 'nombre',
            redirect: (id: string) => location.pathname.startsWith("/grupos") ? `/grupos/${id}/configuracion` : `/informes/${id}`,
            sortable: true,
            body: (rowData: any) => (
                <Flex
                    alignItems="center"
                >
                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {rowData?.nombre}
                    </Text>

                    {(rowData?.fundae === true && moduloFUNDAE) &&
                        <Box
                            ml="auto"
                            mr="20%"
                        >
                            <AlertsGrupo grupo={rowData} />
                        </Box>
                    }
                </Flex>
            )
        },
        {
            header: 'Curso',
            field: 'curso',
            key: 'curso',
            redirect: (id: string) => location.pathname.startsWith("/grupos") ? `/grupos/${id}/configuracion` : `/informes/${id}`,
            body: (rowData: any) => (
                <Text
                    color="font"
                    fontSize="14px"
                    fontWeight="500"
                >
                    {rowData?.curso?.nombre}
                </Text>
            )
        },
        {
            header: 'Progreso Matrículas',
            field: 'progresoMatriculas',
            key: 'progresoMatriculas',
            style: { width: "200px" },
            redirect: (id: string) => location.pathname.startsWith("/grupos") ? `/grupos/${id}/configuracion` : `/informes/${id}`,
            body: (rowData: any) => (
                <Flex gap="5px" alignItems="center" pr="10%">
                    <Progress
                        value={parseInt(rowData?.meta?.progresoLeccionesMatriculas) || 0}
                        flex="1"
                        h="7px"
                        w="150px"
                        rounded="10px"
                        sx={{ '& > div': { background: "variant" } }}
                    />
                    <Text
                        color="font"
                        fontSize="14px"
                        fontWeight="500"
                    >
                        {parseInt(rowData?.meta?.progresoLeccionesMatriculas) || 0} %
                    </Text>
                </Flex>
            )
        },
        {
            header: "",
            field: "",
            key: "",
            style: { width: "50px" },
            body: (rowData: any) => (
                <Flex pos="relative">
                    <Actions
                        duplicate={{
                            title: "Copiar grupo",
                            isDisabled: contenido,
                            disableAlert: contenido ? "No tiene permisos para realizar la acción" : undefined,
                            onClick: (e?: any) => {
                                grupoRef.current = rowData;

                                onOpen()
                            }
                        }}
                        remove={{
                            title: "Eliminar grupo",
                            isDisabled: rowData?.meta?.totalMatriculas > 0 || contenido,
                            disableAlert: contenido ? "No tiene permisos para realizar la acción" : "No se puede eliminar un grupo con matrículas",
                            onClick: () => {
                                onEventRemove(rowData)
                            }
                        }}
                    />
                </Flex>
            )
        }
    ];

    return (actions && isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre))
        ? columns
        : deleteAcctions(columns)
}