import { Field } from 'formik';
import { FormLabel, FormControl, FormErrorMessage, Flex, Box, Icon, border } from '@chakra-ui/react';
import { BiSolidInfoCircle } from 'react-icons/bi';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./TextEditor.scss"

type FormTextEditorProps = {
    name: string;
    label?: string;
    placeholder?: string;
    isRequired?: boolean;
    controlStyle?: React.CSSProperties;
    height?: string;
    onChange: (e: any, form: any) => void;
    stylesEditor?: React.CSSProperties;
};

export const FormTextEditor = ({
    name,
    label,
    placeholder,
    controlStyle = {},
    stylesEditor = {},
    isRequired = false,
    height = '320px',
    onChange,
}: FormTextEditorProps) => {

    const toolbarOptions = [
        ["bold", "italic", "underline", "strike"],

        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        [{ indent: "-1" }, { indent: "+1" }],

        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ align: [] }],

        ["code-block"],
        ["image"],
    ];

    const modules = {
        toolbar: toolbarOptions,
        clipboard: {
            matchVisual: false
        }
    };

    const formats = [
        "header",
        "image",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "code-block",
        "list",
        "bullet",
        "indent",
        "link",
        "align",
    ];

    return (
        <Field name={name}>
            {({ field, form }: { field: any; form: any }) => (
                <FormControl style={controlStyle} isInvalid={form.errors[name] && form.touched[name]}>
                    {label &&
                        <FormLabel
                            htmlFor={name}
                            display="flex"
                            gap="3px"
                            color="font"
                            fontSize="14px"
                            fontWeight="400"
                            textTransform="capitalize"
                        >
                            {label}
                        </FormLabel>
                    }
                    <Box pos="relative" className="form-texteditor">
                        <ReactQuill
                            value={field.value || ''}
                            onChange={(content) => {
                                form.setFieldValue(field.name, content);
                                if (onChange) {
                                    onChange(field.name, content);
                                }
                            }}
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            placeholder={placeholder}
                            style={{
                                minHeight: height,
                                backgroundColor: "#FFFFFF",
                                border: "1px solid #E2E8F0",
                                ...stylesEditor
                            }}
                        />

                        {isRequired &&
                            <Icon
                                as={BiSolidInfoCircle}
                                boxSize="15px"
                                color={(form.errors[name] && form.touched[name]) ? "red" : "font"}
                                pos="absolute"
                                right="-7px"
                                top="-7px"
                            />
                        }
                    </Box>

                    <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
                </FormControl>
            )}
        </Field>
    );
};