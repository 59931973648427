import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, Flex } from "@chakra-ui/react"
import { EndpointTypes, RelationshipTypes } from "../../../../../../shared/utils/Types/EndpointTypes";
import { useClient } from "../../../../../../shared/context/client.context";
import { useData } from "../../../../../../shared/hooks/useData";
import { LeccionTipoEnum } from "../../../../../../shared/utils/Types/LeccionTipoEnum";
import { LeccionInt } from "../../../../../../interfaces/CursosInt";
import { GruposInt } from "../../../../../../interfaces/GruposInt";
import { useParams } from "react-router-dom";
import { useDataRelationship } from "../../../../../../shared/hooks/useDataRelationship";
import { MatriculasInt } from "../../../../../../interfaces/MatriculasInt";

interface Props {
    grupo: GruposInt;
}

export const TabsAsistencias = ({
    grupo
}: Props) => {
    const { id } = useParams();
    const client = useClient()

    const { data: lecciones } = useData({
        endpoint: EndpointTypes.LECCIONES,
        client: client,
        query: {
            tipo: LeccionTipoEnum.SESION_VIVO,
            publicada: "true",
            curso: grupo?.cursoId,
            sortBy: "sesionVivo"
        }
    })

    const { data: asistencias } = useData({
        endpoint: EndpointTypes.ASISTENCIAS,
        client: client,
        query: {
            grupo: id!
        },
        ignoreRequest: !id
    })

    const { data: matriculas } = useDataRelationship({
        id: id,
        client: client,
        endpoint: EndpointTypes.GRUPOS,
        relationship: RelationshipTypes.MATRICULAS,
        specificQuery: {
            deBaja: "false",
        },
    })

    const getAsistencia = ({
        asistencias,
        leccionId,
        userId

    }: {
        asistencias: any[],
        leccionId: string | undefined,
        userId: string | undefined | null
    }) => {
        if (!leccionId || !userId) return "false"

        let asistencia: string = "false"

        const asistenciaLeccion = asistencias?.find((a: any) => a.leccionId === leccionId)

        if (asistenciaLeccion?.asistentes) {
            Object.entries(asistenciaLeccion?.asistentes)?.map(([key, value]) => {
                if (key === userId) {
                    return asistencia = value as string
                }
            })
        }

        return asistencia
    }

    return (
        <Box
            border="1px solid #E6E6EA"
            bg="purewhite"
            rounded="20px"
            w="100%"
            p="40px"
        >
            <Table variant="simple">

                {lecciones?.data?.length ?
                    <>
                        <Thead>
                            <Tr>
                                <Th>Lecciones</Th>
                                {matriculas?.data.map((matricula: MatriculasInt, index: number) => (
                                    <Th key={index}>{matricula?.user?.nombreCompleto}</Th>
                                ))}
                            </Tr>
                        </Thead>


                        <Tbody
                        >
                            {lecciones?.data?.map((leccion: LeccionInt) => (
                                <Tr key={leccion.id}>
                                    <Td>{leccion.nombre}</Td>
                                    {matriculas?.data.map((matricula: MatriculasInt) => {
                                        const asistencia = getAsistencia({
                                            asistencias: asistencias?.data,
                                            leccionId: leccion?.id,
                                            userId: matricula?.user?.id
                                        });

                                        return (
                                            <Td
                                            >
                                                <Box
                                                    boxSize="22px"
                                                    border="3px solid"
                                                    borderColor={asistencia ? "success" : "fail"}
                                                    rounded="100%"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    display="flex"
                                                >
                                                    <Box
                                                        rounded="100%"
                                                        bg={asistencia ? "success" : "fail"}
                                                        boxSize="10px"
                                                    />
                                                </Box>
                                            </Td>
                                        );
                                    })}
                                </Tr>
                            ))}
                        </Tbody>
                    </>
                    :
                    <Flex
                        alignItems="center"
                        justifyContent="center"
                        w="100%"
                        py="50px"
                    >
                        <Text
                            fontSize="24px"
                            color="font"
                            fontWeight="600"
                            maxW="700px"
                            textAlign="center"
                        >
                            No existen asistencias actualmente.
                        </Text>
                    </Flex>
                }

            </Table>
        </Box>
    )
}
